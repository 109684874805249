import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/_helpers'

import '../../_metronic/_assets/sass/pages/login/classic/login-1.scss'

export default function AuthTemplate({ child }) {
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                id="kt_login"
            >
                <div
                    className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat bg-blue p-2 p-lg-10"
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/bg/selecBgLogo.png')})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'bottom',
                    }}
                >
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        <div className="d-flex flex-column justify-content-center">
                            <h3 className="font-size-h1 mb-0 text-white login-logo ">
                                <a className="navbar-brand" href="#">
                                    <img
                                        src={toAbsoluteUrl('/media/logos/selecIOTLoginLogo.png')}
                                        height="33"
                                        className="d-inline-block align-top"
                                        alt="selec"
                                    />
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
                    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                        {child()}
                    </div>
                </div>
            </div>
        </div>
    )
}
