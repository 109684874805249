import axios from 'axios'

export function getClients(filterObj) {
  return axios.post('client/find', filterObj)
}

export function createClient(request) {
  return axios.post('client', request)
}

export function updateClient(id, request) {
  return axios.patch('client/' + id, request)
}

export function deleteClient(id) {
  return axios.delete('client/' + id)
}

export function getClientById(id) {
  return axios.get('client/' + id)
}

export function getIndustries() {
  return axios.get('client/industries')
}

export function getPreSignedUrlForClient(request) {
  return axios.post('s3/uploadFile', request)
}

export function uploadFileInS3(url, file) {
  return axios({
    method: 'post',
    url: url,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function getDeviceModelListForClient(request) {
  return axios.post(`/deviceModel/client`, request)
}

export function getDeviceById(id) {
  return axios.get('device/' + id)
}

export function updateDevice(id, request) {
  return axios.patch('device/' + id, request)
}

export function getDeviceData(request) {
  //return axios.post('device/data', request)
  return axios.post('/device/mongodb/data', request)
}

export function getEnergyReportsForDevice(request) {
  //return axios.post('device/energy/data', request)
  return axios.post('/device/mongodb/energy/data', request)
}

export function getLatestDeviceData(request) {
  //return axios.post('device/getData', request)
  return axios.post('device/mongodb/getData', request)
}

export function getTopClients() {
  return axios.get('client/admin/top-clients')
}

export function getWeeklyCustomersGrowth(request) {
  return axios.post('client/admin/weekly-growth', request)
}

export function getCustomerLocations() {
  return axios.get('client/admin/client-locations')
}

export function writeParametersForDevice(request) {
  return axios.post('/device/writeParameters', request)
}

export function updateDeviceParameters(request) {
  return axios.patch('/device/updateDeviceParameters', request)
}

export function readParametersForDevice(request) {
  return axios.post('/device/readParameter', request)
}

export function updateForDeviceVersion(req) {
  return axios.patch('/device/firmware/update', req)
}

export function updateCriticalParameter(req) {
  return axios.patch('/device/critical/update-parameter', req)
}


/* SLD CRUD BEGINS */
export function getSld(id) {
  return axios.get('sld/' + id)
}

export function getSldDeviceData(id) {
  //return axios.get('sld/deviceData/' + id)
  return axios.get('sld/mongodb/deviceData/' + id)
}

export function createSld(request) {
  return axios.post('sld', request)
}

export function updateSld(id, request) {
  return axios.patch('sld/' + id, request)
}

export function findSld(request) {
  return axios.post('sld/find', request)
}

export function deleteSld(id) {
  return axios.delete('sld/' + id)
}
/* SLD CRUD END */


/* LOCATIONS CRUD BEGINS */
export function createLocation(request) {
  return axios.post('location', request)
}

export function updateLocation(id, request) {
  return axios.patch('location/' + id, request)
}

export function getLocationById(id) {
  return axios.get('location/' + id)
}

export function deleteLocation(id) {
  return axios.delete('location/' + id)
}

export function getAllLocations(request) {
  return axios.post('location/find', request)
}
/* LOCATIONS CRUD END */


/* SPACES CRUD BEGINS */
export function createSpace(request) {
  return axios.post('space', request)
}

export function updateSpace(id, request) {
  return axios.patch('space/' + id, request)
}

export function getSpaceById(id) {
  return axios.get('space/' + id)
}

export function deleteSpaceById(id) {
  return axios.delete('space/' + id)
}

export function getAllSpaces(request) {
  return axios.post('space/find', request)
}
/* SPACES CRUD END */


/* Widget CRUD Start */
export function getWidgets(filterObj) {
  return axios.post('/widget/find', filterObj)
}

export function addNewWidget(request) {
  return axios.post('/widget', request)
}

export function updateWidget(id, request) {
  return axios.patch('/widget/' + id, request)
}

export function getWidgetById(id) {
  return axios.get('/widget/' + id)
}

export function deleteWidget(id) {
  return axios.delete('/widget/' + id)
}
/* Widget CRUD End */


/* Alarms CRUD Start */
export function getAlarms(filterObj) {
  return axios.post('/alert/find', filterObj)
}

export function createAlarm(request) {
  return axios.post('/alert', request)
}

export function updateAlarm(id, request) {
  return axios.patch('/alert/' + id, request)
}

export function getAlarmById(id) {
  return axios.get('/alert/' + id)
}

export function deleteAlarm(id) {
  return axios.delete('/alert/' + id)
}

export function getAddrFromLatLng(req) {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${req.lat},${req.lng}&key=AIzaSyDvHDfaPBkk3uSozh-l5jUegrKl0X4-jIg`,
  )
}
/* Alarms CRUD End */


/* GLOBAL SEARCH SETUP STARTS */
export function GLOBALSEARCH(req) {
  return axios.post('/search', req)
}
/* GLOBAL SEARCH SETUP ENDS */


/* Scheduled setup starts */
export function generateScheduledReport(req) {
  return axios.post('/reports', req)
}
export function getScheduledReport(req) {
  return axios.post('/reports/find', req)
}
export function findScheduledReport(id) {
  return axios.get('/reports/' + id)
}
export function updateScheduledReport(id, req) {
  return axios.patch('/reports/' + id, req)
}
export function deleteScheduledReport(id) {
  return axios.delete('/reports/' + id)
}
/* Schedule setup ends */
