/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useSelector } from 'react-redux'

export function AsideMenuListClient({ layoutProps }) {
  const location = useLocation()
  const { user } = useSelector((state) => state.auth)
  const { permissions } = useSelector((state) => state.permissions)

  const isAllAccessGranted = permissions?.all || false
  const accessToPages = Object.keys(permissions?.access || {})

  const routes = useLocation()

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? ` ${'menu-item-active'} menu-item-open `
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Dashboard */}

        {/* Dashboard Module begin*/}

        {
          (isAllAccessGranted || accessToPages.includes('dashboard')) && (
            <li
              className={`menu-item ml-3 mr-3 
          ${routes.pathname.includes(`/client/client-dashboard/${user.clientId}`)
                  ? 'menu-item-active menu-item-open'
                  : ''
                }`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/client/client-dashboard/${user.clientId}`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      '/media/svg/icons/DashboardIconSheet/dashboard copy.svg',
                    )}
                  />
                </span>
                <span className="menu-text font-weight-bold">Dashboard</span>
              </NavLink>
            </li>
          )}

        {/* Dashboard Module end*/}

        {/* Devices Module begin*/}
        {/* <li
          className={`menu-item  ml-3 mr-3 ${
            routes.pathname.toLowerCase().includes('/gateway') ||
            routes.pathname.includes('/client/edit-device/') ||
            routes.pathname.includes('/client/map/') ||
            routes.pathname.toLowerCase().includes('/slave') ||
            routes.pathname
              .toLowerCase()
              .includes('/client/client-device-configure/')
              ? 'menu-item-active menu-item-open'
              : ''
          }`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to={`/client/client-view/${user.clientId}`}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/devices-24px.svg',
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">My Devices</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text font-weight-bold">My Devices</span>
                </span>
              </li>

              <li
                className={`menu-item ml-3 mt-2 ${
                  routes.pathname.toLowerCase().includes('/gateway') ||
                  routes.pathname.includes('/client/edit-device/') ||
                  routes.pathname.includes('/client/map/')
                    ? 'menu-item-active menu-item-open'
                    : ''
                }`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link pl-8"
                  to={`/client/client-view/${user.clientId}/Gateway`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Gateway Devices</span>
                </NavLink>
              </li>

              <li
                className={`menu-item  ml-3 mt-2 ${
                  routes.pathname.toLowerCase().includes('/slave') ||
                  routes.pathname
                    .toLowerCase()
                    .includes('/client/client-device-configure/')
                    ? 'menu-item-active menu-item-open'
                    : ''
                }`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link pl-8"
                  to={`/client/client-view/${user.clientId}/Slave`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Slave Devices</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* Devices Module end*/}

        {/* New Devices Module begin*/}

        {(isAllAccessGranted || accessToPages.includes('device')) && (

          <li
            className={`menu-item  ml-3 mt-2 mr-3 ${routes.pathname.toLowerCase().includes('/gateway') ||
              routes.pathname.includes('/client/edit-device/') ||
              routes.pathname.includes('/client/map/') ||
              routes.pathname.toLowerCase().includes('/slave') ||
              routes.pathname.toLowerCase().includes('/client/client-device-list') ||
              routes.pathname.includes('/client/client-device-view/') ||
              routes.pathname
                .toLowerCase()
                .includes('/client/client-device-configure/')
              ? 'menu-item-active menu-item-open'
              : ''
              }`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/client-device-list/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/DashboardIconSheet/devices-24px.svg',
                  )}
                />
              </span>
              <span className="menu-text font-weight-bold">My Devices</span>
            </NavLink>
          </li>

        )}

        {/* New Devices Module end*/}

        {/* SCHEDULED REPORTS MODULE STARTS */}

        {(isAllAccessGranted || accessToPages.includes('reports')) && (
          <li
            className={`menu-item  ml-3 mt-2 mr-3 ${routes.pathname.includes('/client/scheduled')
              ? 'menu-item-active menu-item-open'
              : ''
              }`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/scheduled/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                <i className="fa fa-file" aria-hidden="true"></i>
              </span>
              <span className="menu-text font-weight-bold">Scheduled Reports</span>
            </NavLink>
          </li>
        )}

        {/* SCHEDULED REPORTS MODULE ENDS */}

        {/* Location Module begin*/}
        {(isAllAccessGranted || accessToPages.includes('location')) && (
          <li
            className={`menu-item  ml-3 mt-2 mr-3 ${routes.pathname.includes('/client/location') ||
              routes.pathname.includes('/client/space')
              ? 'menu-item-active menu-item-open'
              : ''
              }`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/location/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/DashboardIconSheet/people_alt-24px.svg',
                  )}
                />
              </span>
              <span className="menu-text font-weight-bold">My Locations</span>
            </NavLink>
          </li>
        )}
        {/* Network Module Start */}

        {(isAllAccessGranted || accessToPages.includes('network')) && (

          <li
            className={`menu-item  ml-3 mr-3 mt-2 ${routes.pathname.includes('/client/hybrid-network')
              ? // ||
              // routes.pathname.includes('/client/space')
              'menu-item-active menu-item-open'
              : ''
              }`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/hybrid-network/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                {/* <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/people_alt-24px.svg',
                )}
              /> */}
                <i className="fas fa-network-wired"></i>

              </span>
              <span className="menu-text font-weight-bold">Network</span>
            </NavLink>
          </li>

        )}
        {/* Network Module End */}


        {/* Alarm Module begin*/}
        {(isAllAccessGranted || accessToPages.includes('device')) && (
          <li
            className={`menu-item  ml-3 mr-3 mt-2 ${routes.pathname.includes('/client/alarms')
              ? // ||
              // routes.pathname.includes('/client/space')
              'menu-item-active menu-item-open'
              : ''
              }`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/alarms/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                {/* <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/people_alt-24px.svg',
                )}
              /> */}
                <i className="fas fa-bell"></i>
              </span>
              <span className="menu-text font-weight-bold">Alarms</span>
            </NavLink>
          </li>
        )}

        {/* Location Module end*/}


        {/* SLD Module begin*/}
        {(isAllAccessGranted || accessToPages.includes('sld')) && (
          <li
            className={`menu-item ${getMenuItemActive(
              `/client/sld`,
              false,
            )} ml-3 mt-2 mr-3`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={`/client/sld/${user.clientId}/`}>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/icons/DashboardIconSheet/people_alt-24px.svg',
                  )}
                />
              </span>
              <span className="menu-text font-weight-bold">Hybrid View</span>
            </NavLink>
          </li>
        )}
        {/*  SLD Module end*/}

        {/*begin::1 Level*/}
        {/* Settings Module Begin*/}
        <li
          className={`menu-item  ${getMenuItemActive(
            '/client/settings',
            true,
          )} ${getMenuItemActive('/client/settings', true)} ml-3 mt-2 mr-3`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/client/settings">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/settings-24px-1.svg',
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text font-weight-bold">Settings</span>
                </span>
              </li>
              {/*begin::2 Level*/}

              <li
                className={`menu-item ml-3 mt-2 ${getMenuItemActive(
                  '/client/profile',
                  true,
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link pl-8"
                  to={`/client/profile/${user.clientId}`}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">My Profile</span>
                </NavLink>
              </li>
              {(isAllAccessGranted || accessToPages.includes('roles')) && (
                <li
                  className={`menu-item ml-3 ${getMenuItemActive(`/client/role`)}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link pl-8"
                    to={`/client/role/role-list/${user.clientId}`}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text font-weight-bold">Roles</span>
                  </NavLink>
                </li>
              )}
              {(isAllAccessGranted || accessToPages.includes('users')) && (
                <li
                  className={`menu-item ml-3 ${getMenuItemActive(`/client/user`)}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link pl-8"
                    to={`/client/user/user-list/${user.clientId}`}
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text font-weight-bold">Users</span>
                  </NavLink>
                </li>
              )}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/* Settings Module End*/}

        {/* Logout begin*/}
        <li
          className={`menu-item ${getMenuItemActive('/logout', false)}  ml-3 mt-2 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/exit_to_app-24px.svg',
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Logout</span>
          </NavLink>
        </li>

        {/* Logout end*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
