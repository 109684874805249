import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import CustomToast from '../../../components/CustomToast/CustomToast'
import { verifyCode } from '../_redux/authCrud'
import * as auth from '../_redux/authRedux'
import PasswordStrengthIndicator from './PasswordStrengthIndicator'

function ForgotPassword(props) {
  const history = useHistory()

  const params = useParams()

  const initialValuesCode = {
    newPassword: '',
  }
  const { intl } = props

  const ForgotPasswordSchemaCode = Yup.object().shape({
    newPassword: Yup.string()
      .required()
      .min(8),
  })

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const testCode = async (values, { setStatus }) => {
    try {
      await verifyCode({
        code: params.tempPass,
        newPassword: values.newPassword,
        email: params.email,
      })
      toast(
        <CustomToast type="success" message={`Password Changed Successfully !!!`} />,
      )
      history.push('/')
    } catch (error) {
      setStatus(error.message)
    }
  }

  const formikCode = useFormik({
    initialValues: initialValuesCode,
    validationSchema: ForgotPasswordSchemaCode,
    onSubmit: testCode,
  })

  return (
    <>
      <div className="login-form login-forgot" style={{ display: 'block' }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Verification Code</h3>

          <div className="text-muted font-weight-bold">
            Enter your verification code to reset your password
          </div>
        </div>
        <form
          onSubmit={formikCode.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          <div className="form-group fv-plugins-icon-container">
            <TextField
              key="code_input"
              id="outlined-code"
              label="Verification Code"
              autoComplete="off"
              variant="outlined"
              margin="none"
              size="medium"
              fullWidth
              value={params.tempPass}
              disabled={true}
              placeholder={params.tempPass}
            />
          </div>
          <div className="form-group fv-plugins-icon-container">
            <TextField
              type={showPassword ? 'text' : 'password'}
              key="newPassword_input"
              id="outlined-newPassword"
              label="New Password"
              autoComplete="off"
              variant="outlined"
              margin="none"
              size="medium"
              fullWidth
              value={formikCode.values.newPassword}
              error={formikCode.touched.newPassword && formikCode.errors.newPassword}
              {...formikCode.getFieldProps('newPassword')}
            />
            <div className="d-flex">
              {formikCode.touched.newPassword && formikCode.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formikCode.errors.newPassword}
                  </div>
                </div>
              ) : null}
              <span
                toggle="#password-field"
                className={
                  showPassword
                    ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10'
                    : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'
                }
                style={{ position: 'relative', top: '-35px' }}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              />
            </div>
          </div>
          {formikCode.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formikCode.status}</div>
            </div>
          )}
          <div className="mb-10 alert alert-custom alert-dismissible">
            <PasswordStrengthIndicator password={formikCode.values.newPassword} />
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bolder px-7 py-3 my-3 mx-4"
              disabled={formikCode.isSubmitting}
              style={{ borderRadius: '2px' }}
            >
              Submit
            </button>
            <Link to="/">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-secondary font-weight-bolder px-7 py-3 my-3 mx-4"
                style={{ borderRadius: '2px' }}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
