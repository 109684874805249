import React from 'react'
export const ellipsis = (text = '', n = 10) => {
  return text.length <= n ? (
    <span>{text}</span>
  ) : (
    <span style={{ cursor: 'pointer' }} title={text}>
      {text.substring(0, n)}...
    </span>
  )
}
