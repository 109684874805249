import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { BuilderPage } from './pages/BuilderPage'
import { MyPage } from './pages/MyPage'
import { ProfilePage } from './modules/Team/pages/ProfilePage'
import SetDynamicTitle from './components/SetDynamicTitle'

const GoogleMaterialPage = lazy(() =>
  import('./modules/GoogleMaterialExamples/GoogleMaterialPage'),
)
const ReactBootstrapPage = lazy(() =>
  import('./modules/ReactBootstrapExamples/ReactBootstrapPage'),
)
const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'))

const DevicePage = lazy(() => import('./modules/Device/pages/DevicePage'))

const ClientRoutes = lazy(() => import('./modules/Client/pages/ClientRoutes'))

const TeamPage = lazy(() => import('./modules/Team/pages/TeamPage'))

const DashboardRoutes = lazy(() =>
  import('./modules/Dashboard/pages/DashboardRoutes'),
)

const ActivityLogRoutes = lazy(() =>
  import('./modules/ActivityLog/Pages/ActivityLogRoutes'),
)

const NotificationAlertsRoutes = lazy(() =>
  import('./modules/Notification-Alerts/Pages/Notification-AlertsRoutes'),
)

export default function BasePage({ user }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect
          exact
          from="/"
          to={
            ['selecadmin'].includes(user.role.toLowerCase())
              ? '/dashboard'
              : ['selecUser'].includes(user.role)
                ? '/client/client-list'
                : `/client/client-dashboard/${user.clientId}`
          }
        />
        <Route path="/device" component={DevicePage} />
        <Route path="/client" component={ClientRoutes} />
        <Route path="/team" component={TeamPage} />
        <Route
          path="/dashboard"
          component={() => (
            <>
              <SetDynamicTitle title="Selec IoT - Dashboard" />
              <DashboardRoutes />
            </>
          )}
        />
        <Route
          path="/activity-log"
          component={() => (
            <>
              <SetDynamicTitle title="Selec IoT - Activity Logs" />
              <ActivityLogRoutes />
            </>
          )}
        />
        <Route
          path="/notification-alerts"
          component={() => (
            <>
              <SetDynamicTitle title="Selec IoT - Notifications" />
              <NotificationAlertsRoutes />
            </>
          )}
        />
        <Route
          path="/update-profile"
          component={() => (
            <>
              <SetDynamicTitle title="Selec IoT - Profile" />
              <ProfilePage />
            </>
          )}
        />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  )
}
