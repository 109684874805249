import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import CustomToast from './CustomToast/CustomToast'
import { toast } from 'react-toastify'
//import {image64toCanvasRef} from "./ReusableUtils";
const CustomFileUploader = ({
  value = null,
  accept,
  onUpload,
  modelImage = false,
  setModelCroppedImage,
}) => {
  const [displayFile, setDisplayFile] = useState(value)
  const [fileTypeAccept, setFileTypeAccept] = useState('')

  const style = {
    dropzone: {
      boxSizing: 'border-box',
      height: '70px',
      border: '2px dashed #51429C',
      borderRadius: '5px',
      backgroundColor: '#f8f8f8',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      padding: '27px',
      cursor: 'pointer',
      transition: 'border .24s ease -in -out',
    },
  }

  useEffect(() => {
    switch (accept) {
      case 'image':
        setFileTypeAccept('.jpeg, .jpg')
        break
      case 'file':
        setFileTypeAccept('text/csv')
        break
      default:
        toast(<CustomToast type="error" message={'Unsupported File Type'} />)
        break
    }
  }, [])

  const onFileChange = (files) => {
    if (files.length && files[0].type.includes(accept) === -1) {
      toast(<CustomToast type="error" message={'Invalid File Format'} />)
      return
    }
    if (modelImage) {
      onUpload(URL.createObjectURL(files[0]))
    } else {
      onUpload(files[0])
      setDisplayFile(() => URL.createObjectURL(files[0]))
    }
  }

  const onDeleteImage = () => {
    onUpload(null)
    setDisplayFile(null)
    setModelCroppedImage(null)
  }

  return (
    <>
      {value && (
        <div className="d-flex align-items-end">
          <div className="mr-5">
            {accept === 'image' && (
              <div>
                <img
                  style={{ width: '74px', height: '74px' }}
                  src={displayFile || value}
                ></img>
              </div>
            )}
          </div>

          <div className="ml-2">
            {
              <Dropzone onDrop={(acceptedFiles) => onFileChange(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <button
                    key={`Admin-Submit-button`}
                    type="button"
                    className="btn btn-primary font-weight-bolder pl-3 pr-3 pt-1 pb-1 mr-2 font-12 rounded-0"
                    style={{ cursor: 'pointer' }}
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      accept={fileTypeAccept}
                      multiple={false}
                    />
                    Replace
                  </button>
                )}
              </Dropzone>
            }
            <button
              key={`Admin-Submit-button`}
              type="button"
              className="btn btn-secondary bg-secondary font-weight-bolder pl-3 pr-3 pt-1 pb-1 mr-2 font-12 rounded-0"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onDeleteImage()
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )}

      {!value && (
        <Dropzone
          onDragEnter={() =>
          (document.getElementById('dropzone-ondrop-file').style.border =
            '2px dashed #e36c4b')
          }
          onDragLeave={() =>
          (document.getElementById('dropzone-ondrop-file').style.border =
            '2px dashed #51429C')
          }
          onDrop={(acceptedFiles) => onFileChange(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                id="dropzone-ondrop-file"
                style={style.dropzone}
                {...getRootProps()}
              >
                <input
                  {...getInputProps()}
                  accept={fileTypeAccept}
                  multiple={false}
                />
                <i
                  className="fa fa-plus"
                  style={{ color: '#51429C', fontSize: '12px' }}
                ></i>
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </>
  )
}

export default CustomFileUploader
