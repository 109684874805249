import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { connect, useDispatch } from 'react-redux'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { setPassword } from '../_redux/authCrud'
import CustomLoadingAnimation from '../../../components/CustomLoadingAnimation'
import { toast } from 'react-toastify'
import CustomToast from '../../../components/CustomToast/CustomToast'
import { permissionActions } from '../_redux/permissionsRedux'
import PasswordStrengthIndicator from './PasswordStrengthIndicator'

const SetPassword = (props) => {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [initialValues, setInitialValues] = useState({
    userName: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const setPasswordSchema = Yup.object().shape({
    userName: Yup.string().required(),
    currentPassword: Yup.string()
      .required()
      .min(8),
    newPassword: Yup.string()
      .required()
      .min(8),
    confirmPassword: Yup.string()
      .required()
      .min(8)
      .oneOf(
        [Yup.ref('newPassword')],
        'New Password and Confirm Password did not match!',
      ),
  })

  const [isRequested, setIsRequested] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState()

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: setPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true)
        setSubmitting(true)
        if (values.newPassword != values.confirmPassword) {
          setStatus('New password and confirm password should be same')
          return
        }
        let response = await setPassword(
          values.userName,
          values.currentPassword,
          values.confirmPassword,
          { register: true }
        )
        if (response.token) {
          let authObj = {}
          authObj.token = response.token
          if (response.clientId) {
            authObj.clientId = response.clientId
          }
          props.login(authObj)

          /* PERMISSIONS FUNCTIONALITY STARTS */

          let userPermissions = _.get(response, 'roles.permissions', {})
          let roleOfUser = response.role
          var permissions = {}

          /*
          • ALL = TRUE ----> FOR SELECADMIN/CLIENTADMIN ----> ACCESS TO EVERY THING
          • ALL = TRUE ----> FOR NOW WE ARE GIVING SELECUSER ALL VALUE TO TRUE & LET BACKEND HANDLE THE ACCESS
          • ALL = FALSE ----> FOR CLIENT USER
          • r ----> ONLY READ ACCESS TO PARTICULAR MODULE
          • w ----> READ/WRITE ACCESS TO PARTICULAR MODULE
          */

          if (
            roleOfUser.toLowerCase().includes('admin') ||
            roleOfUser.toLowerCase() === 'selecuser'
          ) {
            permissions.all = true
          } else {
            permissions.all = false
            let access = {}
            Object.keys(userPermissions || {}).forEach((page) => {
              if (userPermissions[page]?.write) {
                access[page] = 'w'
              } else if (
                userPermissions[page]?.read &&
                !userPermissions[page]?.write
              ) {
                access[page] = 'r'
              }
            })
            permissions.access = access
          }
          dispatch(
            permissionActions.setPermissions({
              type: 'SET_PERMISSIONS',
              payload: permissions,
            }),
          )

          /* PERMISSIONS FUNCTIONALITY ENDS */

          props.fulfillUser({
            ...response,
            fullname: response.name || 'TestUser',
            token: response.token || undefined,
            id: response.id,
            clientId: response.clientId || undefined,
            role: response.role || undefined,
            image: response.profileImage || undefined,
          })
        }
        toast(
          <CustomToast
            type="success"
            message={'Password changed successfully !!!'}
          />,
        )
        setIsRequested(true)
        history.push('/')
      } catch (error) {
        setStatus(error.message || error)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (params.userName) {
      setInitialValues({
        ...initialValues,
        userName: params.userName,
        currentPassword: params.password,
      })
    }
  }, [params])

  return (
    <>
      {isRequested && <Redirect to="/" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <div className="text-center mb-10 mb-lg-20">
            {/* <h3 className="font-size-h1">Set New Password </h3> */}
            <h3 className="font-size-h1">
              {' '}
              <FormattedMessage id="AUTH.CHANGE_PASSWORD" />
            </h3>
            <div className="text-muted font-weight-bold">
              Change your default password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'userName',
                )}`}
                name="userName"
                placeholder="User Name"
                disabled={params.userName ? true : false}
                {...formik.getFieldProps('userName')}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.userName}</div>
                </div>
              ) : null}
            </div>
            {!params?.password && (
              <div className="form-group fv-plugins-icon-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    'currentPassword',
                  )}`}
                  name="currentPassword"
                  disabled={params.password ? true : false}
                  placeholder="Current Passowrd"
                  {...formik.getFieldProps('currentPassword')}
                />
                <div className="d-flex">
                  {formik.touched.currentPassword &&
                    formik.errors.currentPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.currentPassword}
                      </div>
                    </div>
                  ) : null}
                  <span
                    toggle="#password-field"
                    className={
                      showPassword
                        ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10'
                        : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'
                    }
                    style={{ position: 'relative', top: '-35px' }}
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                    onMouseDown={handleMouseDownPassword}
                  />
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type={showNewPassword ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'newPassword',
                )}`}
                name="newPassword"
                placeholder="New Password"
                value={formik.values.newPassword}
                onChange={(e) => {
                  formik.handleChange(e)
                  setNewPassword(e.target.value)
                }}
              // {...formik.getFieldProps('newPassword')}
              />
              <div className="d-flex">
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.newPassword}</div>
                  </div>
                ) : null}
                <span
                  toggle="#password-field"
                  className={
                    showNewPassword
                      ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10'
                      : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'
                  }
                  style={{ position: 'relative', top: '-35px' }}
                  onClick={() => {
                    setShowNewPassword(!showNewPassword)
                  }}
                  onMouseDown={handleMouseDownPassword}
                />
              </div>
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'confirmPassword',
                )}`}
                name="confirmPassword"
                placeholder="Confirm Password"
                {...formik.getFieldProps('confirmPassword')}
              />
              <div className="d-flex">
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.confirmPassword}
                    </div>
                  </div>
                ) : null}
                <span
                  toggle="#password-field"
                  className={
                    showConfirmPassword
                      ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10'
                      : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'
                  }
                  style={{ position: 'relative', top: '-35px' }}
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword)
                  }}
                  onMouseDown={handleMouseDownPassword}
                />
              </div>
            </div>

            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            <div className="mb-10 alert alert-custom alert-dismissible">
              <PasswordStrengthIndicator password={newPassword} />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}

      {/* Loader */}
      <CustomLoadingAnimation isLoading={loading} />
    </>
  )
}

export default injectIntl(connect(null, auth.actions)(SetPassword))
