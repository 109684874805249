import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import * as client from '../app/modules/Client/_redux/clientRedux'
import * as spaces from '../app/modules/Client/_redux/SpacesRedux'
import * as permissions from '../app/modules/Auth/_redux/permissionsRedux'
import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice'
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice'
import { remarksSlice } from '../app/modules/ECommerce/_redux/remarks/remarksSlice'
import { specificationsSlice } from '../app/modules/ECommerce/_redux/specifications/specificationsSlice'

export const rootReducer = combineReducers({
  client: client.reducer,
  spaces: spaces.reducer,
  auth: auth.reducer,
  permissions: permissions.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
})

export function* rootSaga() {
  yield all([auth.saga()])
}
