import axios from 'axios'

export const LOGIN_URL = `auth/login`
export const REGISTER_URL = 'auth/register'
export const REQUEST_PASSWORD_URL = 'auth/forgot-password'
export const SET_PASSWORD_URL = 'auth/changePassword'
export const ME_URL = 'api/me'

export function login(email, password) {
  return axios.post('auth/login', { email, password })
}

export function register(email, fullname, username, password) {
  return axios.post('auth/register', {
    email,
    fullname,
    username,
    password,
  })
}

export function setPassword(email, password, newPassword, headers = {}) {
  return axios.post('auth/changePassword', {
    email,
    password,
    newPassword,
  }, { headers })
}

export function forgotPassword(email) {
  return axios.post('auth/forgot-password', { email })
}

export function verifyCode(request) {
  return axios.post('auth/confirm-code', request)
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get('api/me')
}
