/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useRef } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ContentRoute, Layout } from '../_metronic/layout'
import BasePage from './BasePage'
import { Logout, AuthPage } from './modules/Auth'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import Legal from './pages/Legal'
import SetPassword from './modules/Auth/pages/SetPassword'
import { actions } from './modules/Auth/_redux/authRedux'
import AuthTemplate from './components/AuthTemplate'


export function Routes() {
  const history = useHistory();
  const pageRef = useRef(history?.location?.pathname)
  const dispatch = useDispatch();

  if (pageRef?.current?.startsWith("/change-password")) {
    pageRef.current = history.location.pathname;

  }

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user,
    }),
    shallowEqual,
  )


  history.listen((location) => {
    ReactGA.pageview(location.pathname)
  })

  if (isAuthorized && pageRef?.current?.startsWith("/change-password")) {
    dispatch(actions.logout());
    pageRef.current = null
  }

  return (
    <Switch>
      <ContentRoute
        path="/change-password/:userName?/:password?"
        component={() => <AuthTemplate child={() => <SetPassword />} />}
      />
      <Route exact={true} path="/privacy-policy" component={Legal} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/" />
      ) : (
        <Layout>{user && user.role && <BasePage user={user} />}</Layout>
      )}
    </Switch>
  )
}
