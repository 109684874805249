import axios from 'axios'

export function getSignedUrlForDevice(request) {
  return axios.post('s3/uploadFile', request)
}

export function uploadFileInS3(url, file) {
  return axios({
    method: 'post',
    url: url,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function getDevices(filterObj) {
  return axios.post('/deviceModel/find', filterObj)
}

export function findBinaryfileData(filterObj) {

  return axios.post('/deviceModel/deviceModelBinary/find', filterObj)
}

export function addBinaryfile(reqBody) {
  return axios.post('/deviceModel/add-binary', reqBody)
}

export function getDistinctDeviceModels(filterObj) {
  return axios.post('/deviceModel/distinct', filterObj)
}

export function createDeviceModel(reqBody) {
  return axios.post('deviceModel', reqBody)
}

export function getDeviceById(id) {
  return axios.get('deviceModel/' + id)
}

export function updateDeviceModel(id, reqBody) {
  return axios.patch('deviceModel/' + id, reqBody)
}

export function getDeviceInventoryDetails(filterObj) {
  return axios.post('device/find', filterObj)
}

export function getStatusDetails(filterObj) {
  return axios.post('/device/status/find', filterObj)
}

export function createMultipleDevices(reqBody) {
  return axios.post('device/multiple', reqBody)
}

export function getDeviceData(reqBody) {
  //return axios.post('/device/data', reqBody)
  return axios.post('/device/mongodb/data', reqBody)

}

export function updateDevice(reqBody) {
  return axios.patch('device/multiple', reqBody)
}

export function syncConfig(reqBody) {
  return axios.post('/device/syncConfig', reqBody)
}

export function getTotalInventory(filterObj) {
  return axios.post('/device/totalinventory', filterObj)
}

export function getPopularDevices() {
  return axios.get('/device/admin/popular-devices')
}


export function alertDeviceWise(filterObj) {
  return axios.post('/alert/devicewise', filterObj)
}

export function getGatewayWise(filterObj) {
  return axios.post('/device/gatewaywise', filterObj)
}

export function getCSVReportsForDevices(reqBody) {
  //return axios.post('/device/download-data', reqBody)
  return axios.post('/device/mongodb/download-data', reqBody)
}

export function getCSVReportsForDevicesPDF(reqBody) {
  //return axios.post('/device/download-data', reqBody)
  return axios.post('/device/mongodb/download-data-pdf', reqBody, { responseType: 'blob' })
}

export function changeCertificateStatus(reqBody) {
  return axios.post('/device/certificateStatus', reqBody)
}


export function getTimezoneByDeviceId(deviceId) {
  return axios.get(`/device/get-timezone/${deviceId}`)
}

/** 
 * @description delete device by _id of device , api return bad request exception if the device is connected to any slave
 * @param {string} deviceId - device id
 * @returns {Promise<boolean>} boolean true if device deleted successfully 
 */
export function deleteDeviceById(deviceId) {
  return axios.delete(`/device/delete/${deviceId}`)
}

/**
 * @description this api un-assign the device with provided id 
 * @param {string} deviceId
 * @returns {Promise<boolean>} boolean true if device un-assign successfully 
 */
export function unAssigneDevive(deviceId) {
  return axios.patch(`/device/un-assign/${deviceId}`)
}

/**
 * @description this api discconnect  the slave device with provided slave id
 * @param {string} slaveDeviceId 
 * @returns {Promise<boolean>} boolean true if device slave device disconnected successfully successfully 
 */
export function diconnectSlave(slaveDeviceId) {
  return axios.patch(`/device/disconnect-slave/${slaveDeviceId}`)
}