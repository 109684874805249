/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { ContentRoute } from '../../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import PasswordVerification from './PasswordVerification'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import AuthTemplate from '../../../components/AuthTemplate'

export function AuthPage() {
  return (
    <>
      <AuthTemplate
        child={() => {
          return (
            <Switch>
              <ContentRoute exact={true} path="/" component={Login} />
              <ContentRoute path="/registration" component={Registration} />
              <ContentRoute
                exact={true}
                path="/forgot-password"
                component={PasswordVerification}
              />
              <ContentRoute
                path="/forgot-password/:tempPass?/:email"
                component={ForgotPassword}
              />
              <Redirect from="/auth" exact={true} to="/" />
              <Redirect to="/" />
            </Switch>
          )
        }}
      />
    </>
  )
}
