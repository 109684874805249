import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import GlobalSearchDropDown from "../../../../app/components/GlobalSearchDropDown/GlobalSearchDropDown";
import { GLOBALSEARCH } from "../../../../app/modules/Client/_redux/clientCrud";
import { toast } from "react-toastify";
import CustomToast from "../../../../app/components/CustomToast/CustomToast";

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: false,
      //  objectPath.get(
      //   uiService.config,
      //   "header.menu.self.display"
      // )
    };
  }, [uiService]);

  const [showDropdown, setShowDropdown] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [debounceObj, setDebounceObj] = useState({})
  const [globalSearchData, setGlobalSearchData] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  const loadOptions = async (inputValue) => {
    try {

      let request = {
        page: 1,
        limit: 10,
        filter: {
          term: inputValue
        },
        sort: { id: -1 },
      }
      const resp = await GLOBALSEARCH(request)
      return resp
    } catch (error) {
      toast(<CustomToast type="error" message={error.message} />)
    }
  }

  const globalSearchResults = async (tempString, value) => {
    try {
      setShowLoader(true)
      let resp = await new Promise((resolve) => {
        if (debounceObj[tempString]) {
          clearTimeout(debounceObj[tempString])
        }
        debounceObj[tempString] = setTimeout(() => {
          let temp = loadOptions(value)
          resolve(temp)
        }, 300)
      })
      setGlobalSearchData(resp?.data || [])
    } catch (error) {
      toast(<CustomToast type="error" message={error.message} />)
    } finally {
      setShowLoader(false)
    }

  }

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {/* {!layoutProps.menuHeaderDisplay && <div />} */}
          {!layoutProps.menuHeaderDisplay && " "}
          {/*end::Header "Menu Wrapper*/}
          <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
            <div className="global-search">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm border-0 px-3"
                    onClick={() =>
                      document.getElementById("kt_subheader_search_form").focus()
                    }
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.5"
                        />
                        <path
                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          opacity="0.6"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  autoComplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value)
                    if (e.target.value === '') {
                      setGlobalSearchData([])
                    }
                    if (e.target.value.length >= 3) {
                      globalSearchResults('tempStr', e.target.value)
                    } else {
                      setGlobalSearchData([])
                    }
                  }}
                  onFocus={() => setShowDropdown(true)}
                  // onBlur={()=>setShowDropdown(false)}
                  className="form-control pl-1 position-relative"
                  id="kt_subheader_search_form"
                  placeholder="Search By location, Client, Device, etc."
                />
                {
                  showLoader && (
                    <div aria-hidden="true" className="loading-indicator-globalsearch">
                      <span className="loading-dot-1"></span>
                      <span className="loading-dot-2"></span>
                      <span className="loading-dot-3"></span>
                    </div>
                  )
                }
                {
                  showDropdown && searchText.length >= 3 && (
                    <GlobalSearchDropDown globalSearchData={globalSearchData} setShowDropdown={setShowDropdown} showLoader={showLoader} setSearchText={setSearchText} />
                  )
                }
              </div>
            </div>
          </ClickAwayListener>
          <div className="row align-items-center">
            {/*begin::Topbar*/}
            {/* <Topbar /> */}
            {/* <span
              className="svg-icon svg-icon-sm pr-lg-2 pl-6"
              title="Notification"
            >
              <SVG
                title="Notification"
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/Notification.svg"
                )}
              />
            </span> */}
            <Topbar />
            {/*end::Topbar*/}
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
