/* Pagination Helprs */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "./AssetsHelpers";

export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span className="svg-icon svg-icon-sm  ml-1 svg-icon-sort">
        <SVG
          src={toAbsoluteUrl(
            "/media/svg/icons/DashboardIconSheet/Static-6.svg"
          )}
        />
      </span>
    );
  else if (order === "asc")
    return (
      <span className=" ml-1">
        <SVG
          src={toAbsoluteUrl(
            "/media/svg/icons/DashboardIconSheet/Ascending-1.svg"
          )}
        />
      </span>
    );
  else if (order === "desc")
    return (
      <span className="ml-1">
        <SVG
          src={toAbsoluteUrl(
            "/media/svg/icons/DashboardIconSheet/Descending-1.svg"
          )}
        />
      </span>
    );
  return null;
};

export const headerSortingClasses = (
  column,
  sortOrder,
  isLastSorting,
  colIndex
) => (sortOrder === "asc" || sortOrder === "desc" ? "sortable-active" : "");
