import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../_redux/authRedux'
import { login } from '../_redux/authCrud'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import { actions } from '../../Client/_redux/clientRedux'
import { permissionActions } from '../_redux/permissionsRedux'

const initialValues = {
  // username: 'kuldeep.mane@codeb.online',
  // password: 'Password@91',
  username: '',
  password: '',
}

function Login(props) {
  const dispatch = useDispatch()
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  })
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        // console.log({ values });
        setLoading(true)
        let response = await login(values.username, values.password)
        setLoading(false)
        let authObj = {}
        authObj.token = response.token
        if (response.clientId) {
          authObj.clientId = response.clientId
        }
        props.login(authObj)
        let userPermissions = _.get(response, 'roles[0].roles.permissions', {})
        let roleOfUser = response.role
        var permissions = {}

        /*
         • ALL = TRUE ----> FOR SELECADMIN/CLIENTADMIN ----> ACCESS TO EVERY THING
         • ALL = TRUE ----> FOR NOW WE ARE GIVING SELECUSER ALL VALUE TO TRUE & LET BACKEND HANDLE THE ACCESS
         • ALL = FALSE ----> FOR CLIENT USER
         • r ----> ONLY READ ACCESS TO PARTICULAR MODULE
         • w ----> READ/WRITE ACCESS TO PARTICULAR MODULE
        */

        if (roleOfUser.toLowerCase().includes('admin') || roleOfUser.toLowerCase() === 'selecuser') {
          permissions.all = true
        } else {
          permissions.all = false
          let access = {}
          Object.keys(userPermissions || {}).forEach(page => {
            if (userPermissions[page]?.write) {
              access[page] = 'w'
            } else if (userPermissions[page]?.read && !userPermissions[page]?.write) {
              access[page] = 'r'
            }
          })
          permissions.access = access
        }
        dispatch(permissionActions.setPermissions({ type: 'SET_PERMISSIONS', payload: permissions }))
        props.fulfillUser({
          ...response,
          fullname: response.name || 'TestUser',
          token: response.token || undefined,
          id: response.id,
          clientId: response.clientId || undefined,
          role: response.role || undefined,
          image: response.profileImage || undefined,
        })
        dispatch(actions.data({ type: 'data', payload: { _id: response.clientId } }))
      } catch (error) {
        setStatus(error.message || error)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-10">
        <h1 className="font-size-h1 font-weight-bolder">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h1>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <TextField
            key="username_input"
            id="outlined-username"
            label="Username"
            autoComplete="off"
            variant="outlined"
            margin="none"
            size="medium"
            fullWidth
            value={formik.values.username}
            error={formik.touched.username && formik.errors.username}
            {...formik.getFieldProps('username')}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <TextField
            type={showPassword ? 'text' : 'password'}
            key="password_input"
            id="outlined-password"
            label="Password"
            autoComplete="off"
            variant="outlined"
            fullWidth
            margin="none"
            size="medium"
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            {...formik.getFieldProps('password')}
          />
          <div className="d-flex">
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
            <span
              toggle="#password-field"
              className={showPassword ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10' : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'}
              style={{ position: 'relative', top: '-35px' }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            />
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bolder px-7 `}
            style={{ borderRadius: '2px' }}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link
            to="/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2 font-weight-bolder"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))
