/* eslint-disable no-unused-vars */
import React from "react";
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import Select from 'react-select'
import { DefaultSelectStyle, reactSelectTheme } from "../../../../app/helpers/styles.helper";

export function PaginationToolbar(props) {
  const { isLoading, paginationProps } = props;
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = [
      { text: "3", value: 3 },
      { text: "5", value: 5 },
      { text: "10", value: 10 },
    ],
  } = paginationProps;
  const style = {
    width: "73px",
  };

  const onSizeChange = (event) => {
    // const newSize = +event.target.value;
    const newSize = event.value
    onSizePerPageChange(newSize);
  };

  return (
    <div className="d-flex align-items-center py-3 justify-content-end">
      {isLoading && (
        <div className="d-flex align-items-center">
          <div className="mr-2 text-muted">Loading...</div>
          <div className="spinner spinner-primary mr-10"></div>
        </div>
      )}
      {/* <select
        disabled={totalSize === 0}
        className={`form-control form-control-sm font-weight-bold mr-4 border-1 bg-white ${totalSize ===
          0 && "disabled"}`}
        onChange={onSizeChange}
        value={sizePerPage}
        style={style}
      >
        {sizePerPageList.map((option) => {
          const isSelect = sizePerPage === `${option.page}`;
          return (
            <option
              key={option.text}
              value={option.page}
              className={`btn ${isSelect ? "active" : ""}`}
            >
              {option.text}
            </option>
          );
        })}
      </select> */}
      <div style={{width: "5.5rem", marginRight: "1rem"}}>
        <Select
        disabled={totalSize === 0}
        onChange={onSizeChange}
        value={{
          value: sizePerPage,
          label: sizePerPage
        }}
        options={sizePerPageList.map((option) => {
          return {
            value: option.value,
            label: option.text,
          }
        })}
        isSearchable={false}
        styles={DefaultSelectStyle}
        theme={reactSelectTheme}
        menuPlacement='auto'
        placeholder=''
        />
      </div>
      <PaginationTotalStandalone className="text-muted" {...paginationProps} />
    </div>
  );
}
