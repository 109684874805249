import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const actionTypes = {
  Data: 'Data Action',
}

const initialState = {
  id: undefined,
  name: undefined,
}

export const reducer = persistReducer(
  { storage, key: 'client-data' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.Data: {
        return { ...action.payload }
      }
      default:
        return state
    }
  },
)

export const actions = {
  data: (payload) => ({ type: actionTypes.Data, payload: { ...payload } }),
}
