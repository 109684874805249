import axios from 'axios'

export function getUsers(filters) {
  return axios.post(`users/findUsers`, filters)
}

export function emailResend(request) {
  return axios.post('/users/resend-verification-link/', request)
}

export function createUsers(request) {
  return axios.post('users', request)
}

export function deleteUsers(id) {
  return axios.delete('users/' + id)
}

export function getUserById(id) {
  return axios.get('users/' + id)
}

export function updateUserById(id, request) {
  return axios.patch('users/' + id, request)
}
export function createSubscriptionById(id, request) {
  return axios.post('users/' + id, request)
}

