import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import SVG from 'react-inlinesvg'

import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { Badge } from 'react-bootstrap'
import { ellipsis } from '../../helpers/elipsis.helpers'

const GlobalSearchDropDown = ({
  globalSearchData,
  setShowDropdown,
  showLoader,
  setSearchText,
}) => {

  const history = useHistory()
  const { clientData, user } = useSelector((store) => ({
    clientData: store.client,
    user: store.auth.user,
  }), shallowEqual)

  const [activeTab, setActiveTab] = useState('all')
  const [data, setData] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  var TABS = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'device',
      label: 'Devices',
    },
    // {
    //   value: 'devicemodel',
    //   label: 'Device Familys',
    // },
    {
      value: 'location',
      label: 'Locations',
    },
    {
      value: 'user',
      label: 'Users',
    },
  ]

  if (!['clientAdmin', 'clientUser'].includes(user.role)) {
    TABS = [
      ...TABS,
      {
        value: 'devicemodel',
        label: 'Device Familys',
      },
    ]

  }

  if (['selecAdmin', 'selecUser'].includes(user.role)) {
    TABS = [
      ...TABS,
      {
        value: 'client',
        label: 'Clients',
      },
    ]
  }

  const handleBlurFocusedList = () => {
    let elm = document.getElementById(`dropdown-result-${activeIndex}`)
    if (elm) {
      elm.blur()
    }
  }

  const layoutForDeviceData = (arr, index) => {
    return (
      <>
        <div
          onMouseEnter={() => {
            handleBlurFocusedList()
            setActiveIndex(0)
          }}
          onClick={() => {
            if (arr.clientId) {
              if (arr.deviceModelType === 'slave' || arr.deviceModelType === 'smart') {
                history.push(
                  `/client/client-device-configure/${arr.clientId}/${arr.deviceModelId}/${arr._id}`,
                )
              } else if (arr.deviceModelType === 'gateway') {
                history.push(
                  `/client/edit-device/${arr.clientId}/${arr.deviceModelId}/${arr._id}`,
                )
              }
              setSearchText('')
              setShowDropdown(false)
            }
          }}
          className="col-12 d-flex align-items-center justify-content-between list-hover-effect h-max-8"
          tabIndex="1"
          id={`dropdown-result-${index + 1}`}
        >
          <div className="d-flex flex-row align-items-center">
            <div className="mr-2">
              <img
                src={arr.deviceModelImage || Error()}
                style={{
                  height: '50px',
                  width: '50px',
                  border: '1px solid #DADADA',
                }}
                onError={(e) => {
                  e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
                  e.target.onError = null
                }}
                className="rounded"
              />
            </div>
            <div>
              <div className="c-black">
                <h2 className="mb-1">{arr.name || 'Untitled'}</h2>
              </div>
              <span className="mr-2 font-weight-medium text-grey">Selec</span>
              <span className="font mr-2 font-weight-medium text-grey">
                | {arr.deviceModelName || 'Untitled'} -{' '}
                {arr.deviceModelVariant || 'Untitled'} |
              </span>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: `${arr.deviceModelType === 'gateway' ? '#9BD2FF' : '#FFDB83'
                    }`,
                  borderRadius: '5px',
                }}
              >
                <span className="text-capitalize">{arr.deviceModelType}</span> Device
              </Badge>
            </div>
          </div>
          {activeTab === 'all' && (
            <div>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: '#343a40',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className="text-white text-capitalize">Device</span>
              </Badge>
            </div>
          )}
        </div>
      </>
    )
  }

  const layoutForDeviceModelData = (arr, index) => {
    return (
      <>
        <div
          onMouseEnter={() => {
            handleBlurFocusedList()
            setActiveIndex(0)
          }}
          onClick={() => {
            if (['selecAdmin', 'selecUser'].includes(user.role)) {
              history.push(`/device/device-view/${arr._id}`)
            } else {
              history.push(
                `/client/client-device-view/${arr.type}/${clientData.payload.id}/${arr._id}`,
              )
            }
            setSearchText('')
            setShowDropdown(false)
          }}
          className="col-12 d-flex align-items-center justify-content-between list-hover-effect h-max-8"
          tabIndex="1"
          id={`dropdown-result-${index + 1}`}
        >
          <div className="d-flex flex-row align-items-center">
            <div className="mr-2">
              <img
                src={arr.image || Error()}
                style={{
                  height: '50px',
                  width: '50px',
                  border: '1px solid #DADADA',
                }}
                onError={(e) => {
                  e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
                  e.target.onError = null
                }}
                className="rounded"
              />
            </div>
            <div>
              <h2 className="text-grey mb-1">
                {arr.name && ellipsis(arr.name, 15)} -{' '}
                {arr.variant && ellipsis(arr.variant, 15)}
              </h2>
              <span className="mr-4 font-weight-medium text-grey">Selec</span>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: `${arr.type === 'gateway' ? '#9BD2FF' : '#FFDB83'
                    }`,
                  borderRadius: '5px',
                  fontWeight: '500',
                }}
              >
                <span className="text-capitalize">{arr.type}</span> Device
              </Badge>
            </div>
          </div>
          {activeTab === 'all' && (
            <div>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: '#343a40',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className="text-white text-capitalize">Device Family</span>
              </Badge>
            </div>
          )}
        </div>
      </>
    )
  }

  const layoutForLocationData = (arr, index) => {
    return (
      <>
        <div
          onMouseEnter={() => {
            handleBlurFocusedList()
            setActiveIndex(0)
          }}
          onClick={() => {
            history.push(`/client/space/${arr.clientId}/${arr._id}/`)
            setSearchText('')
            setShowDropdown(false)
          }}
          className="col-12 d-flex align-items-center justify-content-between list-hover-effect h-max-8"
          id={`dropdown-result-${index + 1}`}
          tabIndex="1"
        >
          <div className="d-flex flex-row align-items-center">
            <div className="mr-2 bg-white">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/Location.svg',
                )}
              />
            </div>
            <div>
              <div className="c-black">
                <h2 className="mb-1">{arr.name || 'Untitled'}</h2>
              </div>
              <span className="mr-2 font-weight-medium text-grey">
                {arr.address && ellipsis(arr.address || '', 40)}
              </span>
            </div>
          </div>
          {activeTab === 'all' && (
            <div>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: '#343a40',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className="text-white text-capitalize">Location</span>
              </Badge>
            </div>
          )}
        </div>
      </>
    )
  }

  const layoutForUserData = (arr, index) => {
    return (
      <>
        <div
          onMouseEnter={() => {
            handleBlurFocusedList()
            setActiveIndex(0)
          }}
          onClick={() => {
            if (['selecUser', 'selecAdmin'].includes(arr.role)) {
              history.push(`/team/user/user-detail/${arr._id}`)
            } else {
              history.push(`/client/user/user-edit/${arr.clientId}/${arr._id}/`)
            }
            setSearchText('')
            setShowDropdown(false)
          }}
          className="col-12 d-flex align-items-center justify-content-between list-hover-effect h-max-8"
          id={`dropdown-result-${index + 1}`}
          tabIndex="1"
        >
          <div className="d-flex flex-row align-items-center">
            <div className="mr-2">
              <img
                src={arr.image || Error()}
                style={{
                  height: '50px',
                  width: '50px',
                  border: '1px solid #DADADA',
                }}
                onError={(e) => {
                  e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
                  e.target.onError = null
                }}
                className="rounded"
              />
            </div>
            <div>
              <div className="c-black">
                <h2 className="mb-1">{arr.name || 'Unknown'}</h2>
              </div>
              <span className="mr-2 font-weight-medium text-grey">{arr.email}</span>
              {arr.phone && (
                <span className="font mr-2 font-weight-medium text-grey">
                  | {arr.phone || ''}
                </span>
              )}
            </div>
          </div>
          {activeTab === 'all' && (
            <div>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: '#343a40',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className="text-white text-capitalize">User</span>
              </Badge>
            </div>
          )}
        </div>
      </>
    )
  }

  const layoutForClientData = (arr, index) => {
    return (
      <>
        <div
          onMouseEnter={() => {
            handleBlurFocusedList()
            setActiveIndex(0)
          }}
          onClick={() => {
            history.push(`/client/client-dashboard/${arr._id}`)
            setSearchText('')
            setShowDropdown(false)
          }}
          className="col-12 d-flex align-items-center justify-content-between list-hover-effect h-max-8"
          id={`dropdown-result-${index + 1}`}
          tabIndex="1"
        >
          <div className="d-flex flex-row align-items-center">
            <div className="mr-2">
              <img
                src={arr.logo || Error()}
                style={{
                  height: '50px',
                  width: '50px',
                  border: '1px solid #DADADA',
                }}
                onError={(e) => {
                  e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
                  e.target.onError = null
                }}
                className="rounded"
              />
            </div>
            <div>
              <div className="c-black">
                <h2 className="mb-1">{arr.name || 'Untitled'}</h2>
              </div>
              <span className="mr-2 font-weight-medium text-grey">
                {arr.industry}
              </span>
              <span className="font mr-2 font-weight-medium text-grey">
                | {arr.contactPersonName || 'Unknown'}
              </span>
              {arr.contactPersonEmail && (
                <span className="font mr-2 font-weight-medium text-grey">
                  | {arr.contactPersonEmail}
                </span>
              )}
            </div>
          </div>
          {activeTab === 'all' && (
            <div>
              <Badge
                pill
                varient="primary text-capitalize"
                style={{
                  backgroundColor: '#343a40',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span className="text-white text-capitalize">Client</span>
              </Badge>
            </div>
          )}
        </div>
      </>
    )
  }

  const layoutForNoResults = () => {
    return (
      <>
        <div>No Results Found</div>
      </>
    )
  }

  const layoutForData = (arr, index) => {
    switch (arr.collection) {
      case 'device':
        return layoutForDeviceData(arr, index)
        break
      case 'devicemodel':
        return layoutForDeviceModelData(arr, index)
        break
      case 'location':
        return layoutForLocationData(arr, index)
        break
      case 'user':
        return layoutForUserData(arr, index)
        break
      case 'client':
        return layoutForClientData(arr, index)
        break
      default:
        return layoutForNoResults()
        break
    }
  }

  useEffect(() => {
    setActiveIndex(0)
    if (activeTab === 'all') {
      setData(globalSearchData)
    } else {
      let desiredData = globalSearchData.filter((partialData) => {
        return partialData.collection === activeTab
      })
      setData(desiredData)
    }
  }, [globalSearchData, activeTab])

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      if (activeIndex <= data.length) {
        let i = activeIndex + 1
        setActiveIndex(i)
      } else {
        setActiveIndex(1)
      }
    }
    if (e.key === 'ArrowUp') {
      if (activeIndex > 1) {
        let i = activeIndex - 1
        setActiveIndex(i)
      } else {
        setActiveIndex(data.length)
      }
    }
    if (e.key === 'Enter' && activeIndex > 0) {
      let elm = document.getElementById(`dropdown-result-${activeIndex}`)
      if (elm) {
        elm.click()
        setSearchText('')
      }
    }
  }

  useEffect(() => {
    // if (activeIndex !== 0) {
    let elm = document.getElementById(`dropdown-result-${activeIndex}`)
    if (elm) {
      elm.focus()
    }
    // }
  }, [activeIndex])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <>
      <div className="card global-search-dropdown shadow-lg">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              {TABS.map((tab, index) => {
                return (
                  <button
                    key={index}
                    className={`btn btn-secondary mr-2 mt-2 dropdown-tab-btn ${activeTab ===
                      tab.value && 'dropdown-active-tab'} `}
                    onClick={() => setActiveTab(tab.value)}
                  >
                    {tab.label}
                  </button>
                )
              })}
            </div>
          </div>
          <div className="row mt-5">
            <div
              style={{ maxHeight: '25rem', overflowY: 'auto' }}
              className="col-sm-12 d-flex flex-column"
            >
              {data.length > 0 &&
                data.map((result, index) => {
                  return layoutForData(result, index)
                })}

              {data.length === 0 && showLoader === false && (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <p>No Results Found</p>
                </div>
              )}
              {data.length === 0 && showLoader === true && (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <p>loading...</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GlobalSearchDropDown
