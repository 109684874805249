import React from "react";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";

export function Pagination(props) {
  const { children, isLoading, paginationProps } = props;
  return (
    <>
      {children}
      <div className="row">
        <div className="col-12 col-sm-8">
          <PaginationLinks paginationProps={paginationProps} />
        </div>
        <div className="col-12 col-sm-4">
          <PaginationToolbar
            isLoading={isLoading}
            paginationProps={paginationProps}
          />
        </div>
      </div>
    </>
  );
}
