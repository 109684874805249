import React from 'react'
import { Helmet } from 'react-helmet'

const SetDynamicTitle = ({ title = 'Selec IoT' }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
}

export default SetDynamicTitle
