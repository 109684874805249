import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, matchPath, useHistory, useParams } from 'react-router-dom'
import objectPath from 'object-path'
import { Brand } from '../brand/Brand'
import { AsideMenu } from './aside-menu/AsideMenu'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import './subDrawer.scss'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { actions } from '../../../../app/modules/Client/_redux/clientRedux'
import { getClientById } from '../../../../app/modules/Client/_redux/clientCrud'
import { ellipsis } from '../../../../app/helpers/elipsis.helpers'
import { toast } from 'react-toastify'
import CustomToast from '../../../../app/components/CustomToast/CustomToast'

export function Aside() {
  const uiService = useHtmlClassService()
  const dispatch = useDispatch()
  const routes = useLocation()
  const history = useHistory()
  const params = useParams()
  const [hasSubMenu, setHasSubMenu] = useState(false)

  const { clientData, user } = useSelector((store) => ({
    clientData: store.client,
    user: store.auth.user,
  }), shallowEqual)

  const [id, setId] = useState(null)
  const [companyName, setCompanyName] = useState('')

  // Match routes for client-view (not client-list) - Gateway & Slave, Import device, aws poc, device-config, roles
  const pathToMatchForAside = [
    '/client/client-dashboard/:clientId?',
    '/client/client-dashboard-widget/:id?',
    '/client/client-view/:clientId?',
    '/client/client-device-view/:deviceType?/:clientId?/',
    '/client/client-device-list/:clientId?/',
    '/client/import-device',
    '/client/edit-device/:clientId?/:deviceModelId?/:deviceId?/',
    '/client/map/:clientId?/:deviceModelId?/:deviceId?',
    '/client/sld/:clientId?',
    '/client/sld/:clientId?/:sldId?',
    '/client/role/role-list/:clientId?',
    '/client/role/role-edit/:clientId?/:roleId?',
    '/client/client-device-configure/:clientId?/:deviceModelId?/:deviceId?/',
    '/client/location/:clientId?/',
    '/client/user/user-list/:clientId?',
    '/client/user/user-edit/:clientId?/:userId?',
    '/client/space/importDevice/:clientId?/:locationId?/:spaceId?/',
    '/client/space/:clientId?/:locationId?/:spaceId?/',
    '/client/alarms/:clientId?',
    '/client/network/:clientId?',
    '/client/hybrid-network/:clientId?',
    '/client/device-alerts/:clientId?/:deviceId?/:alertId?/',
    '/client/hybrid-view/:clientId',
    '/client/scheduled/:clientId',
    '/client/scheduled/add/:clientId',
  ]

  const setReduxForClientData = async (id) => {
    try {
      const res = await getClientById(id)
      if (res) {
        dispatch(actions.data({ type: 'data', payload: res }))
        setCompanyName(res.name)
        setId(res._id)
      }
    } catch (error) {
      toast(<CustomToast type="error" message={error.message} />)
    } finally {

    }
  }

  const clientPaths = matchPath(routes.pathname, { path: pathToMatchForAside, exact: true })

  if (Boolean(clientPaths) && Boolean(clientPaths?.params?.clientId)) {
    const CLIENT_ID = clientPaths.params.clientId
    if (clientData.payload?._id !== CLIENT_ID) {
      if (CLIENT_ID != undefined && CLIENT_ID != 'undefined') {
        setReduxForClientData(CLIENT_ID)
      }
    }
  }

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, 'aside.self.display') === false,
      headerLogo: uiService.getLogo(),
    }
  }, [uiService])

  useEffect(() => {
    setId(clientData.payload?._id)

    setCompanyName(clientData.payload?.name)
    if (['selecAdmin', 'selecUser'].includes(user.role)) {
      if (matchPath(routes.pathname, { path: pathToMatchForAside, exact: false })) {
        setHasSubMenu(true)
        document.body.classList.add('aside-minimize')
        document.body.classList.add('has-subdrawer')
      } else {
        setHasSubMenu(false)
        document.body.classList.remove('has-subdrawer')
      }
    }
    // when user is other than selecAdmin, removing second side menu
    else {
      setHasSubMenu(false)
      document.body.classList.remove('has-subdrawer')
    }
  }, [routes])

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-row `}
      >
        <div className="d-flex flex-column aside-primary">
          <Brand />

          {/* begin::Aside Menu */}
          <div
            id="kt_aside_menu_wrapper"
            className="aside-menu-wrapper flex-column-fluid"
          >
            {layoutProps.disableAsideSelfDisplay && (
              <>
                {/* begin::Header Logo */}
                <div className="header-logo">
                  <Link to="">
                    <img alt="logo" src={layoutProps.headerLogo} />
                  </Link>
                </div>
                {/* end::Header Logo */}
              </>
            )}
            <AsideMenu disableScroll={layoutProps.disableScroll} />
          </div>
        </div>
        {hasSubMenu && (
          <>
            <div
              className="d-flex flex-column subdrawer"
              style={{ backgroundColor: '#EBEBEB', overflowY: 'auto' }}
            >
              <div>
                <h3 className="align-items-baseline d-flex flex-row pb-3">
                  <button
                    type="button"
                    onClick={() => {
                      history.push('/client/client-list')
                    }}
                    className="btn"
                  >
                    <i className="fa fa-angle-left text-dark"></i>
                  </button>
                  <span className="h4 float-right text-dark w-75 text-capitalize">
                    {' '}
                    {/* {companyName.length < 10
                  ? companyName
                  : `${companyName.substring(0, 10)}...`} */}
                    {ellipsis(companyName, 15)}
                  </span>
                </h3>
              </div>
              <hr />
              <div className="d-flex flex-column">
                <Link
                  to={`/client/client-dashboard/${id}`}
                  className={`mb-10 p-3 hover-bg ${routes.pathname.includes(`/client/client-dashboard/${id}`) ||
                    routes.pathname.includes(`/client/client-dashboard-widget/`)
                    ? 'active-link-aside'
                    : ''
                    }`}
                >
                  Dashboard
                </Link>
                <div className="mb-3">
                  <hr />
                  <span className="styleHrRuler">DEVICES</span>
                </div>
                <div className="mb-10 d-flex flex-column">
                  {/* <Link
                  className={`p-3 hover-bg ${
                    routes.pathname.toLowerCase().includes('/gateway') ||
                    routes.pathname.includes('/client/edit-device/') ||
                    routes.pathname.includes('/client/map/')
                      ? 'active-link-aside'
                      : ''
                  }`}
                  to={`/client/client-view/${id}/Gateway`}
                >
                  Gateway Devices
                </Link>
                <Link
                  className={`p-3 hover-bg ${
                    routes.pathname.toLowerCase().includes('/slave') ||
                    routes.pathname
                      .toLowerCase()
                      .includes('/client/client-device-configure/')
                      ? 'active-link-aside'
                      : ''
                  }`}
                  to={`/client/client-view/${id}/Slave`}
                >
                  Slave Devices
                </Link> */}
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.toLowerCase().includes('/client/client-device-list') ||
                      routes.pathname.includes('/client/client-device-view/') ||
                      routes.pathname.includes('/client/edit-device/') ||
                      routes.pathname.includes('/client/map/') ||
                      routes.pathname.includes('/client/client-device-configure/') ||
                      routes.pathname.includes('/client/device-alerts/')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/client-device-list/${id}/`}
                  >
                    Devices
                  </Link>
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.toLowerCase().includes('/client/network/') || routes.pathname.toLowerCase().includes('/hybrid-network')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    // to={`/client/client-view/${user.clientId}`}
                    to={`/client/network/${id}`}
                  >
                    Network
                  </Link>
                  {
                    //   <Link
                    //   className={`p-3 hover-bg ${routes.pathname.toLowerCase().includes('/hybrid-network')
                    //     ? 'active-link-aside'
                    //     : ''
                    //     }`}
                    //   to={`/client/hybrid-network/${id}`}
                    // >
                    //   Network - Hybrid View
                    // </Link>
                  }
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.toLowerCase().includes('/alarms') ||
                      routes.pathname.toLowerCase().includes('/client/alarms/')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    // to={`/client/client-view/${user.clientId}`}
                    to={`/client/alarms/${id}`}
                  >
                    Alarms
                  </Link>
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes('/client/scheduled')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/scheduled/${id}`}
                  >
                    Scheduled Reports
                  </Link>
                </div>
                <div className="mb-3">
                  <hr />
                  <span className="styleHrRuler">LOCATIONS</span>
                </div>
                <div className="mb-10 d-flex flex-column">
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes('/client/location') ||
                      routes.pathname.includes('/client/space')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/location/${id}/`}
                  >
                    Location
                  </Link>
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes('/client/sld')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/sld/${id}/`}
                  >
                    Hybrid View
                  </Link>
                </div>
                <div className="mb-3">
                  <hr />
                  <span className="styleHrRuler">SETTINGS</span>
                </div>
                <div className="mb-10 d-flex flex-column">
                  {/* <Link
                    className={`p-3 hover-bg ${routes.pathname.includes("/client/settings")
                      ? "active-link-aside"
                      : ""
                      }`}
                    to="/"
                  >
                    Client Setting
                  </Link>
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes("/client/profile")
                      ? "active-link-aside"
                      : ""
                      }`}
                    to="/"
                  >
                    Profile
                  </Link>
                   */}
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes('/client/role')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/role/role-list/${id}`}
                  >
                    Roles
                  </Link>
                  <Link
                    className={`p-3 hover-bg ${routes.pathname.includes('/client/user')
                      ? 'active-link-aside'
                      : ''
                      }`}
                    to={`/client/user/user-list/${id}`}
                  >
                    Users
                  </Link>

                </div>
              </div>
            </div>
          </>
        )}
        {/* end::Aside Menu */}
      </div>

      {/* end::Aside */}
    </>
  )
}
