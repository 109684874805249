/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps, userRole }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Dashboard */}

        {
          ["selecAdmin"].includes(userRole) &&
          // {/* Dashboard Module begin*/ }
          (
            <li
              className={`menu-item ${getMenuItemActive(
                "/dashboard",
                false
              )} ml-3 mr-3`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/DashboardIconSheet/dashboard copy.svg"
                    )}
                  />
                </span>
                <span className="menu-text font-weight-bold">Dashboard</span>
              </NavLink>
            </li>
          )
          // {/* Dashboard Module end*/}
        }



        {
          // {/* All Models Module begin*/ }
          ["selecAdmin"].includes(userRole) &&
          (
            <li
              className={`menu-item ${getMenuItemActive(
                "/device/device",
                false
              )}  ml-3 mr-3`}
              aria-haspopup="true"
            >

              <NavLink className="menu-link" to="/device">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/DashboardIconSheet/devices-24px.svg"
                    )}
                  />
                </span>
                <span className="menu-text font-weight-bold">All Models</span>
              </NavLink>
            </li>
          )
          // {/* All Models Module end*/}
        }


        {/* All Clients Module begin*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/client",
            false
          )} ml-3 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/people_alt-24px.svg"
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">All Clients</span>
          </NavLink>
        </li>

        {/* All Clients Module end*/}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* Settings Module Begin*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/team",
            true
          )} ${getMenuItemActive("/update-profile", true)} ml-3 mr-3`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/team">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/settings-24px-1.svg"
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text font-weight-bold">Settings</span>
                </span>
              </li>
              {/*begin::2 Level*/}

              <li
                className={`menu-item ml-3 mt-2 ${getMenuItemActive(
                  "/update-profile",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link pl-8" to="/update-profile">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">My Profile</span>
                </NavLink>
              </li>

              {/* <li
                className={`menu-item ml-3 ${getMenuItemActive("/team/role")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link pl-8" to="/team/role">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Roles</span>
                </NavLink>
              </li> */}

              {
                ["selecAdmin"].includes(userRole) && (
                  <li
                    className={`menu-item ml-3 mb-2 ${getMenuItemActive(
                      "/team/user",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link pl-8" to="/team/user">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text font-weight-bold">
                        Selec Users
                      </span>
                    </NavLink>
                  </li>
                )
              }


              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/* Settings Module End*/}

        {/* Fabric || AWS SLD Begin*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/fabric",
            false
          )} ml-2 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/fabric">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text font-weight-bold">SLD</span>
          </NavLink>
        </li> */}

        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/fabric/awspoc",
            false
          )} ml-2 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/fabric/awspoc">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text font-weight-bold">SLD</span>
          </NavLink>
        </li> */}

        {/* Fabric || AWS SLD End*/}

        {/* )} */}
        {/*end::1 Level*/}

        {/* Activity log */}

        {/* activity log Module begin*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/activity-log",
            false
          )} ml-3 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/activity-log">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/dashboard copy.svg"
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Activity Log</span>
          </NavLink>
        </li> */}

        {/* activity log Module end*/}

        {/* Notification and Alerts */}

        {/* Notification and Alerts Module begin*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/notification-alerts",
            false
          )} ml-3 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification-alerts">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/dashboard copy.svg"
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Notification and Alerts</span>
          </NavLink>
        </li> */}

        {/* Notification and Alerts Module end*/}

        {/* Logout begin*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/logout",
            false
          )}  ml-3 mr-3`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/DashboardIconSheet/exit_to_app-24px.svg"
                )}
              />
            </span>
            <span className="menu-text font-weight-bold">Logout</span>
          </NavLink>
        </li>

        {/* Logout end*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text font-weight-bold">Components</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* Material-UI */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text font-weight-bold">Material UI</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text font-weight-bold">Material UI</span>
                </span>
              </li> */}

        {/* Inputs */}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material/inputs",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/google-material/inputs"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Inputs</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav"> */}
        {/*begin::3 Level*/}
        {/* <li
                      className={`menu-item  ${getMenuItemActive(
                        "/google-material/inputs/autocomplete"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/google-material/inputs/autocomplete"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text font-weight-bold">Autocomplete</span>
                      </NavLink>
                    </li> */}
        {/*end::3 Level*/}
        {/* </ul>
                </div>
              </li> */}
        {/*end::2 Level*/}
        {/* </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}

        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text font-weight-bold">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/e-commerce",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/e-commerce">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text font-weight-bold">eCommerce</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text font-weight-bold">eCommerce</span>
                </span>
              </li> */}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/customers"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/e-commerce/customers">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Customers</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/e-commerce/products"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/e-commerce/products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text font-weight-bold">Products</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}
        {/* </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
