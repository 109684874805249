/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from 'react'
import {
  Nav,
  Tab,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Badge,
  Image,
} from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SVG from 'react-inlinesvg'
import objectPath from 'object-path'
import { useParams, useHistory } from 'react-router-dom'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import BootstrapTable from 'react-bootstrap-table-next'
import * as _ from 'lodash'
// service imports
import {
  getNotifications,
  updateMultipleNotifications,
} from '../../../../../app/modules/Notification-Alerts/_redux/NotificationService'
import { findActivityLogs } from '../../../../../app/modules/ActivityLog/_redux/activitylog'
import { getUsers } from '../../../../../app/modules/Team/_redux/UserService'
// custom component imports
import CustomToast from '../../../../../app/components/CustomToast/CustomToast'
import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { getDeviceInventoryDetails } from '../../../../../app/modules/Device/_redux/DeviceService'
import * as moment from 'moment'
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}

export function UserNotificationsDropdown() {
  const { user } = useSelector(
    (store) => ({
      user: store.auth.user,
    }),
    shallowEqual,
  )
  const [key, setKey] = useState(['clientAdmin'].includes(user?.role) ? 'Alarms' : 'Logs')
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [noDataFound, setNoDataFound] = useState(false)
  const [noNotifications, setNoNotifications] = useState(false)
  const [logs, setLogs] = useState(undefined)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(undefined)

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    filter: ['clientAdmin', 'clientUser'].includes(user?.role)
      ? {
        clientId: user?.clientId,
        table: { $nin: ['login'] },
      }
      : {
        table: {
          $nin: ['login'],
        },
      },
    sort: { createdAt: -1 },
  })

  // const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const history = useHistory()
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, 'extras.notifications.layout') ===
        'offcanvas',
    }
  }, [uiService])

  const [notifications, setNotifications] = useState([])

  const titleColumnView = (cellContent, row, rowIndex) => {
    return (
      <div key={`${row._id} ${rowIndex}`}>
        <Image
          src={toAbsoluteUrl(
            '/media/svg/icons/DashboardIconSheet/add_alarm-24px.svg',
          )}
          className="border"
          style={{
            borderRadius: '50%',
          }}
          onError={(e) => {
            e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
            e.target.onError = null
          }}
        />
      </div>
    )
  }

  const messageView = (cellContent, row, rowIndex) => {
    return (
      <>
        <span>{row.defaultMessage}</span>
        <p className="text-muted m-0">
          {moment(row.createdAt).format('YYYY-MM-DD hh:mm A')}
        </p>
      </>
    )
  }

  const notificationColumns = [
    {
      dataField: '',
      text: '',
      formatter: titleColumnView,
      headerStyle: {
        paddingLeft: '10px',
        width: '10px',
      },
      style: {
        width: '10px',
        paddingLeft: '10px',
      },
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: '',
      text: '',
      formatter: messageView,
      headerAttrs: {
        hidden: true,
      },
    },
  ]

  const getDeviceInventoryDetailsByIds = async (filterObj, data) => {
    let deviceIds = Object.keys(_.keyBy(data, 'deviceId'))
    let request = {
      ...filterObj,
      filter: {
        clientId: user?.clientId,
        _id: {
          $in: deviceIds,
        },
      },
    }

    const deviceDetails = await getDeviceInventoryDetails(request)
    data.map((devices) => {
      devices['deviceName'] = deviceDetails.data.filter((device) => {
        if (device._id === devices.deviceId) return device
      })[0]?.name
      return devices
    })
    return data
  }

  const getNotificationAndAlerts = async () => {
    try {
      const filterObj = {
        page: 1,
        limit: 10,
        filter: {
          clientId: user?.clientId,
          type: 'alarm',
          read: false,
        },
        sort: { createdAt: -1 },
      }
      setNotifications(undefined)
      const unreadResp = await getNotifications(filterObj)
      if (unreadResp && unreadResp.data && unreadResp.data.length > 0) {
        let responseData = getDeviceInventoryDetailsByIds(filterObj, unreadResp.data)
        Promise.all([responseData]).then((values) => {
          setNotifications(values[0])
        })
        setUnreadNotificationCount(unreadResp.count)
      }
      // if there are no unread notifications then showing already read notifications
      if (unreadResp && unreadResp.data && unreadResp.data.length === 0) {
        const readResp = await getNotifications({
          ...filterObj,
          filter: {
            clientId: user?.clientId,
            type: 'alarm',
            read: true,
          },
        })
        if (readResp && readResp.data && readResp.data.length > 0) {
          let responseData = getDeviceInventoryDetailsByIds(filterObj, readResp.data)
          Promise.all([responseData]).then((values) => {
            setNotifications(values[0])
          })
        } else {
          setNotifications(undefined)
          setNoNotifications(true)
        }
        setUnreadNotificationCount(undefined)
        return
      }
    } catch (error) {
      setNotifications(undefined)
      setUnreadNotificationCount(undefined)
      setNoNotifications(true)
      toast(<CustomToast type="error" message={error.message} />)
    }
  }

  const updateUnreadNotifications = async (request) => {
    try {
      const updateResp = await updateMultipleNotifications(request)
      if (updateResp.failed.length > 0) {
        toast(
          <CustomToast
            type="info"
            message={`Failed to update ${updateResp.failed.length} Notifications`}
          />,
        )
      }
    } catch (error) {
      toast(<CustomToast type="error" message={error.message} />)
    }
  }

  const getActivityLogs = async () => {
    try {
      setLoading(true)
      setLogs(undefined)
      const logs = await findActivityLogs(filters)
      let data = logs.data || []
      if (!(data.length > 0)) {
        setNoDataFound(true)
      }
      var unique
      if (['selecadmin', 'selecuser'].includes(user?.role.toLowerCase())) {
        unique = _.uniq(_.map(data, 'userId'))
      } else {
        let userIdForClient = data
          .filter((elm) => !elm.role.includes('selec'))
          .map((elm) => elm.userId)
        unique = [...new Set(userIdForClient)]
      }

      let request = {
        page: 1,
        limit: 10,
        filter: { _id: { $in: unique } },
      }
      const client = await getUsers(request)

      const finalData = data.map((log) => {
        log['userName'] =
          client.data.filter((clientDetail) => log.userId === clientDetail._id)[0]
            ?.name || ''
        if (
          ['selecadmin', 'selecuser'].includes(log.role.toLowerCase()) &&
          ['clientadmin', 'clientuser'].includes(user?.role.toLowerCase())
        ) {
          log['userName'] =
            log.role.toLowerCase() === 'selecadmin' ? 'Selec Admin' : 'Selec User'
        }
        return log
      })

      setLogs(finalData)
    } catch (error) {
      setLogs([])
      toast(<CustomToast type="error" message={error.message} />)
    } finally {
      setLoading(false)
    }
  }

  const loader = () => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '300px' }}
        >
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ width: 40, height: 40 }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </>
    )
  }

  const noData = () => {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '300px' }}
        >
          <h5>No Data Found</h5>
        </div>
      </>
    )
  }

  const TABLE_MAPPER = {
    devicemodels: ' as a device family.',
    clients: 'as a customer.',
    devices: ' as a device model.',
    alerts: ' to alarms.',
    widgets: ' in widgets.',
    locations: ' as location.',
    scheduledreports: ' as scheduled report.',
    roles: ' as role.',
    sld: ' as sld.',
    spaces: ' as space.',
  }

  const EVENT_MAPPER = {
    create: ' has added',
    delete: ' has deleted',
    update: ' has updated',
    login: ' has logged in.',
    patch: ' has modified',
    'change password': ' has changed password.',
  }

  const ROLE_MAPPER = {
    selecAdmin: 'Selec Admin',
    selecUser: 'Selec User',
    clientAdmin: 'Client Admin',
    clientUser: 'Client User',
  }

  const getLogMessage = (row) => {
    return (
      <>
        {_.isEqual(
          _.sortBy(['_id', 'clientId', 'status']),
          _.sortBy(Object.keys(row.request)),
        ) ? (
          ` has assigned ${row.request._id} to ${row.request.clientId}`
        ) : _.isEqual(
          _.sortBy(['_id', 'clientId', 'parameters']),
          _.sortBy(Object.keys(row.request)),
        ) ? (
          ` has modified parameters of ${row.request._id}`
        ) : (
          <>
            {/* name changed as per UI */}
            <span>{EVENT_MAPPER[row.event]}</span>
            {['login', 'change password'].includes(row.event) ? (
              ''
            ) : (
              <span>
                {' '}
                {row?.request?.name
                  ? row?.request?.name
                  : typeof row.request === 'string'
                    ? row.request
                    : Object.keys(row.request)
                      .filter((elm) => elm !== '_id' && elm !== 'clientId')
                      .join(', ')}
              </span>
            )}
            {row.table.toLowerCase() === 'users' && row?.request?.role
              ? ` as ${ROLE_MAPPER[row?.request?.role]}`
              : TABLE_MAPPER[row.table]}
          </>
        )}
      </>
    )
  }

  const titleColumnViewForActivity = (cellContent, row, rowIndex) => {
    return (
      <>
        {row.userName ? (
          <span>
            <strong className="text-capitalize">{row.userName}</strong>{' '}
            <span>
              <span>{row.message || getLogMessage(row)}</span>
            </span>
            <p className="text-muted m-0">
              {moment(row.createdAt).format('yyyy-MM-DD , hh:mm:ss')}
            </p>
          </span>
        ) : (
          ''
        )}
      </>
    )
  }

  const imageViewForActivity = (cellContent, row, rowIndex) => {
    return (
      <div className="m-0 p-0">
        {/* add image as per invision design */}
        <Image
          src={toAbsoluteUrl('/media/error/No_Image_Available.png')}
          className="border"
          style={{
            height: '30px',
            width: '30px',
            borderRadius: '50%',
          }}
          onError={(e) => {
            e.target.src = toAbsoluteUrl('/media/error/No_Image_Available.png')
            e.target.onError = null
          }}
        />
      </div>
    )
  }

  const columns = [
    {
      dataField: '',
      text: '',
      formatter: imageViewForActivity,
      headerStyle: {
        paddingLeft: '10px',
        width: '10px',
      },
      style: {
        width: '10px',
        paddingLeft: '10px',
      },
      headerAttrs: {
        hidden: true,
      },
    },
    {
      dataField: '',
      text: '',
      formatter: titleColumnViewForActivity,
      headerAttrs: {
        hidden: true,
      },
    },
  ]

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      let tempArray = []
      notifications.map((item) => {
        if (item.status === 0) {
          let obj = {
            ...item,
            status: 1,
          }
          tempArray.push(obj)
        }
      })
      if (tempArray && tempArray.length > 0) {
        updateUnreadNotifications(tempArray)
      }
    }
  }, [notifications, setNotifications])

  useEffect(() => {
    if (key === 'Logs') {
      getActivityLogs()
    } else {
      getNotificationAndAlerts()
    }
  }, [key])
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-sm pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/DashboardIconSheet/Notification.svg',
                )}
              />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  {['selecAdmin'].includes(user?.role)
                    ? 'Alarms & Logs'
                    : 'Notification Alarms & Logs'}
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-sm pulse pulse-primary"
                id="kt_quick_notifications_toggle"
                onClick={() => {
                  if (['selecAdmin'].includes(user?.role)) {
                    getActivityLogs()
                  } else {
                    getNotificationAndAlerts()
                  }
                }}
              >
                <span className="svg-icon svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      '/media/svg/icons/DashboardIconSheet/Notification.svg',
                    )}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xl">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-3 rounded-top"
                key="user_notification"
              // style={{ backgroundImage: `url(${bgImage})` }}
              >
                {/* <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    23 new
                  </span>
                </h4> */}

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-active-border-primary px-2 custom-border-bottom"
                    onSelect={(_key) => setKey(_key)}
                  >
                    {['clientAdmin'].includes(user?.role) && (
                      <Nav.Item className="nav-item" as="li">
                        <Nav.Link
                          eventKey="Alerts"
                          className={`nav-link show ${key === 'Alerts' ? 'active' : ''
                            }`}
                        >
                          Notification & Alarms
                          <Badge
                            pill
                            variant="secondary"
                            className="text-bold-600 ml-1"
                          >
                            {unreadNotificationCount}
                          </Badge>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {/* <Nav.Item as="li">
                    <Nav.Link
                        eventKey="Events"
                        className={`nav-link show ${
                          key === "Events" ? "active" : ""
                        }`}
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Logs"
                        className={`nav-link show ${key === 'Logs' ? 'active' : ''}`}
                      >
                        Activity Log
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    {['clientAdmin'].includes(user?.role) && (
                      <Tab.Pane eventKey="Alerts">
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll"
                          style={{ maxHeight: '320px', position: 'relative' }}
                        >
                          {notifications && notifications.length > 0 ? (
                            <>
                              <BootstrapTable
                                wrapperClasses="table-responsive "
                                classes="table bg-white table-custom table-vertical-center overflow-hidden"
                                bordered={false}
                                bootstrap4
                                remote
                                columns={notificationColumns}
                                data={notifications}
                                keyField="_id"
                              />
                            </>
                          ) : noNotifications ? (
                            noData()
                          ) : (
                            loader()
                          )}
                          {/* <div className="d-flex align-items-center mb-6">
                          <div className="symbol symbol-40 symbol-light-primary mr-5">
                            <span className="symbol-label">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Home/Library.svg"
                                )}
                                className="svg-icon-lg svg-icon-primary"
                              ></SVG>
                            </span>
                          </div>
                          <div className="d-flex flex-column font-weight-bold">
                            <a
                              href="#"
                              className="text-dark text-hover-primary mb-1 font-size-lg"
                            >
                              Briviba SaaS
                            </a>
                            <span className="text-muted">
                              PHP, SQLite, Artisan CLIмм
                            </span>
                          </div>
                        </div> */}
                        </PerfectScrollbar>
                        <div className="d-flex flex-column text-right font-weight-bolder p-2 border-top">
                          <a onClick={() => history.push('/notification-alerts')}>
                            View All {'>'}
                          </a>
                        </div>
                      </Tab.Pane>
                    )}
                    <Tab.Pane eventKey="Events" id="topbar_notifications_events">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="navi navi-hover scroll my-4"
                        style={{ maxHeight: '300px', position: 'relative' }}
                      >
                        {/* <a href="#" className="navi-item">
                          <div className="navi-link">
                            <div className="navi-icon mr-2">
                              <i className="flaticon2-line-chart text-success"></i>
                            </div>
                            <div className="navi-text">
                              <div className="font-weight-bold">
                                New report has been received
                              </div>
                              <div className="text-muted">23 hrs ago</div>
                            </div>
                          </div>
                        </a> */}
                      </PerfectScrollbar>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: '320px', position: 'relative' }}
                      >
                        {logs && logs.length > 0 ? (
                          <>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              classes="table bg-white table-custom table-vertical-center overflow-hidden"
                              bordered={false}
                              bootstrap4
                              remote
                              columns={columns}
                              data={logs}
                              keyField="_id"
                            />
                          </>
                        ) : noDataFound ? (
                          noData()
                        ) : (
                          loader()
                        )}
                      </PerfectScrollbar>
                      <div className="d-flex flex-column text-right font-weight-bolder p-2 border-top">
                        <a onClick={() => history.push('/activity-log')}>
                          View All {'>'}
                        </a>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}
