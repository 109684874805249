import React from 'react'
import './customToast.scss'

const CustomToast = (props) => {
  const { type, message } = props

  const showIcon = (messageType) => {
    switch (messageType) {
      case 'success':
        return <i className="far fa-check-circle"></i>
      case 'warning':
        return <i className="fas fa-exclamation-triangle"></i>
      case 'error':
        return <i className="fa fa-times-circle"></i>
      default:
        return <i className="fas fa-info-circle"></i>
    }
  }

  return (
    <div className="d-flex align-items-center custom-toast h-100">
      <div className={`${type} p-3 h-100 d-flex align-items-center`}>
        {showIcon(type)}
      </div>

      <div className="pt-10 pb-5 pl-3 pr-3 h-100">
        <h6 className="text-capitalize">{type}</h6>
        <p className="m-0">{message}</p>
      </div>
    </div>
  )
}

export default CustomToast
