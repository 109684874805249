import React, { useMemo } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { AsideMenuListClient } from "./AsideMenuListClient";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { useSelector } from 'react-redux'

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const { user } = useSelector((state) => state.auth)

  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {["selecAdmin", "selecUser"].includes(user?.role) ? (
          <AsideMenuList layoutProps={layoutProps} userRole={user?.role} />
        ) :
          (
            <AsideMenuListClient layoutProps={layoutProps} />
          )}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
