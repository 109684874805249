import { createMuiTheme } from '@material-ui/core'

export const DefaultSelectStyle = {
  control: (styles, state) => {
    return {
      ...styles,
      height: 28,
      '&:hover': {
        borderColor: '#51429C',
      },
      borderColor:
        state.selectProps.errors && state.isFocused
          ? '#51429C'
          : state.selectProps.errors && !state.isFocused
          ? '#FF0000'
          : '#C0C0C0',
      boxShadow: 'none',
      '&:active': {
        borderColor: '#51429C',
      },
    }
  },
  menuList: (base) => {
    return {
      ...base,
      maxHeight: 160,
    }
  },
}

// styling for react select
export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#F4F4F4',
    primary: '#51429C',
  },
})

export const ToggleStyles = (status) =>
  createMuiTheme({
    overrides: {
      MuiToggleButton: {
        root: {
          '&$selected': {
            backgroundColor: status === 1 ? '#2DA050' : '#E34B4B',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: status === 1 ? '#2DA050' : '#E34B4B',
              color: '#fff',
            },
          },
          color: '#000000',
        },
      },
    },
  })
