import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const actionTypes = {
  SpaceCrumbs: 'Space Crumbs',
}

const initialState = {
  payload: [],
}

export const reducer = persistReducer(
  { storage, key: 'spaces-breadcrums-data' },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SpaceCrumbs: {
        return { ...action.payload }
      }
      default:
        return state
    }
  },
)

export const actions = {
  spaceCrumb: (payload) => ({
    type: actionTypes.SpaceCrumbs,
    payload: { ...payload },
  }),
}
