import axios from 'axios'

export function getNotifications(filterObj) {
  return axios.post('/notification/find', filterObj)
}

export function resolveAlertWithNotification(patchId, request) {
  return axios.patch(`/alert/resolve/${patchId}`, request)
}

export function updateMultipleNotifications(reqBody) {
  return axios.patch('/notification/update/multiple', reqBody)
}
