import React, { useEffect, useState } from 'react'
import { useSubheader } from '../../../../_metronic/layout'
import * as Yup from 'yup'
import { TextField } from '@material-ui/core'
import { Formik } from 'formik'
import CustomFileUploader from '../../../components/CustomFileUploader'
import ReactPhoneInput from 'react-phone-input-2'

import * as actions from '../../Auth/_redux/authRedux'

import 'react-phone-input-2/lib/bootstrap.css'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls'
import { useSnackbar } from 'notistack'
import { setPassword } from '../../Auth/_redux/authCrud'
import { getUserById, updateUserById } from '../_redux/UserService'
import { useSelector } from 'react-redux'
import CustomLoadingAnimation from '../../../components/CustomLoadingAnimation'
import {
  getSignedUrlForDevice,
  uploadFileInS3,
} from '../../Device/_redux/DeviceService'
import { v4 as uuidv4 } from 'uuid'
import { Divider } from '@material-ui/core'
import { Button, Modal } from 'react-bootstrap'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { toast } from 'react-toastify'
import CustomToast from '../../../components/CustomToast/CustomToast'
import { useDispatch } from 'react-redux'
import PasswordStrengthIndicator from "../../Auth/pages/PasswordStrengthIndicator";

export const ProfilePage = () => {
  const subheader = useSubheader()
  subheader.setTitle('My Profile')
  const { user } = useSelector((state) => state.auth)
  const { enqueueSnackbar } = useSnackbar()
  const userDetails = useSelector((store) => store.auth.user)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const dispatch = useDispatch()
  // Image Cropper
  const [modelImage, setModelImage] = useState(null)
  const [modelCroppedImage, setModelCroppedImage] = useState(null)
  const [previousImage, setPreviousImage] = useState(null)
  const [imageCropperModel, setImageCropperModel] = useState(false)
  const [croppingImage, setCroppingImage] = useState()

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    // height: 1000,
    aspect: 1 / 1,
    // setting cropper position in center
    x: 35,
    y: 35,
  })

  const [profileData, setProfileData] = useState({
    image: '',
    name: '',
    // lastName: "",
    email: '',
    phone: '+91',
  })

  const formValidationSchemaProfile = Yup.object().shape({
    name: Yup.string().required('Please Enter Name'),
    // lastName: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email('Please enter valid email')
      .required('Email is Required'),
    phone: Yup.string()
      .required('Phone Number is Required')
      .min(12, 'Invalid Phone Number'),
  })

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      let signedUrlResponse

      if (modelCroppedImage && typeof modelCroppedImage !== 'string') {
        let fileExtention = '.' + modelCroppedImage.name.split('.').pop()
        signedUrlResponse = await getSignedUrlForDevice({
          fileName: uuidv4() + fileExtention,
          contentType: modelCroppedImage.type,
        })
        const formData = new FormData()
        Object.keys(signedUrlResponse.fields).forEach((key) => {
          formData.append(key, signedUrlResponse.fields[key])
        })

        // Actual file has to be appended last.
        formData.append('file', modelCroppedImage)

        await uploadFileInS3(signedUrlResponse.url, formData).catch((err) => {
          throw new Error('Error while uploading image')
        })
        values.image = signedUrlResponse.url + '/' + signedUrlResponse.fields['key']

      }
      if (modelCroppedImage === null || modelCroppedImage === false) {
        values.image = ''
      }
      const resp = await updateUserById(user.id, values)
      dispatch(
        actions.actions.fulfillUser({
          ...user,
          name: values.name,
          fullname: values.name,
          profileImage: resp.image,
        }),
      )
      toast(<CustomToast type="success" message={'Profile Updated Successfully!'} />)
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: 'error' })
      toast(<CustomToast type="error" message={error.message} />)
    } finally {
      setLoading(false)
    }
  }

  const [initialValuesPassword, setInitialValuesPassword] = useState({
    current: '',
    new: '',
    confirm: '',
  })

  const formValidationSchemaPassword = Yup.object().shape({
    current: Yup.string()
      .required('Current Password Required')
      .min(8, 'Current Password must be of at least 8 characters'),
    new: Yup.string()
      .required('New Password Required')
      .min(8, 'New Password must be of at least 8 characters'),
    confirm: Yup.string()
      .required('Confirm Password Required')
      .min(8, 'Confirm Password must be of at least 8 characters')
      .oneOf(
        [Yup.ref('new'), null],
        'New Password and Confirm Password did not match!',
      ),
  })

  const onSubmitPassword = async (values, { resetForm }) => {
    try {
      setLoading(true)
      await setPassword(profileData.email, values.current, values.confirm)
      setSuccessMessage('Password changed Successfully...')
      setTimeout(() => {
        setSuccessMessage(null)
      }, 8000)
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
      resetForm({
        current: '',
        new: '',
        confirm: '',
      })
    }
  }

  const clearMesaage = () => {
    setSuccessMessage(null)
    setErrorMessage(null)
  }

  const getUserData = async () => {
    try {
      setLoading(true)
      const resp = await getUserById(user.id)
      if (!resp.hasOwnProperty('phone')) {
        resp['phone'] = '+91'
      }
      setProfileData(resp)
      setModelCroppedImage(resp.image)
      setModelImage(resp.image)
      setPreviousImage(resp.image)
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: 'error' })
      toast(<CustomToast type="error" message={error.message} />)
    } finally {
      setLoading(false)
    }
  }

  const afterCroppingDone = () => {
    if (!crop) {
      return
    }
    const canvas = document.createElement('canvas')
    const scaleX = croppingImage.naturalWidth / croppingImage.width
    const scaleY = croppingImage.naturalHeight / croppingImage.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      croppingImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )
    const base64Image = canvas.toDataURL('image/jpeg')

    var arr = base64Image.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    const croppedImage = new File(
      [u8arr],
      `${new Date().getTime()}.${mime.split('/')[1]}`,
      {
        type: mime,
      },
    )

    if (croppedImage.size / 1024 / 1024 < 3) {
      setModelImage(base64Image)
      setModelCroppedImage(croppedImage)
      setProfileData({
        ...profileData,
        image: croppedImage,
      })
      setImageCropperModel(false)
    } else {
      // enqueueSnackbar('Select image size smaller than 3mb.', {
      //   variant: 'error',
      // })
      toast(
        <CustomToast type="error" message={'Select image size smaller than 3mb.'} />,
      )

      setModelImage()
      setModelCroppedImage()
      setProfileData({
        ...profileData,
        image: '',
      })
      setImageCropperModel(false)
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let breadcrumbs = [
      {
        title: 'Home',
        pathname: '/dashboard',
      },
      {
        title: 'Setting',
      },
    ]
    subheader.setBreadcrumbs(breadcrumbs)
    getUserData()
  }, [])

  return (
    <>
      <div className="col-sm-6 col-6 mb-2 p-0">
        <div className="h3 font-weight-bold mb-4">My Profile</div>
      </div>
      <Card className="w-100">
        <CardHeader>
          <CardHeaderToolbar>
            <div className="font-weight-bolder h4 pt-2">Personal Info</div>
          </CardHeaderToolbar>
        </CardHeader>
        <Divider className="w-100" style={{ color: '#C0C0C0' }} />
        <CardBody>
          {profileData && (
            <Formik
              enableReinitialize
              initialValues={profileData}
              validationSchema={formValidationSchemaProfile}
              key="key_client_form"
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleReset,
              }) => (
                <>
                  <div className="row">
                    <div className="col-sm-12 mb-5">
                      <label className="font-weight-bolder">Profile Picture</label>
                      <div className="col-sm-5 p-5 d-flex uploader">
                        <CustomFileUploader
                          key="image-key"
                          value={modelImage}
                          accept="image"
                          onUpload={(e) => {
                            // keeping default settings of cropper while uploading new file
                            setCrop({
                              unit: '%',
                              width: 30,
                              aspect: 1 / 1,
                              x: 35,
                              y: 35,
                            })
                            setModelImage(e)
                            if (e) {
                              setImageCropperModel(true)
                            }
                          }}
                          modelImage={true}
                          setModelCroppedImage={setModelCroppedImage}
                        />
                        {!modelImage && (
                          <span className="pl-3">
                            You can upload jpg, gif or png image files. Max size of
                            3mb.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <TextField
                        label="Name"
                        name={`${new Date().getTime()}`}
                        value={values.name}
                        onChange={handleChange('name')}
                        margin="normal"
                        variant="outlined"
                        error={errors.name && touched.name}
                        size="small"
                      />
                      {errors.name && touched.name && (
                        <div className="text-danger d-block">
                          {errors.name || ''}
                        </div>
                      )}
                    </div>
                    {/* <div className="col-sm-6">
                    <TextField
                      label="Last Name"
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      margin="normal"
                      variant="outlined"
                      error={errors.lastName && touched.lastName}
                      size="small"
                    />
                    {errors.lastName && touched.lastName && (
                      <div className="text-danger d-block">
                        {errors.lastName || ""}
                      </div>
                    )}
                  </div> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <TextField
                        type="email"
                        label="Email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        error={errors.email && touched.email}
                        size="small"
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger d-block">
                          {errors.email || ''}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-4 mt-4">
                      <ReactPhoneInput
                        inputExtraProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true,
                        }}
                        value={values.phone}
                        onChange={handleChange('phone')}
                        inputStyle={{
                          width: '100%',
                          height: '10px',
                          borderColor: 'silver',
                        }}
                        error={errors.phone && touched.phone}
                      />

                      {errors.phone && touched.phone && (
                        <div className="text-danger d-block">
                          {errors.phone || ''}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-end mt-5">
                    <button
                      type="button"
                      className="btn btn-secondary font-weight-bold"
                      onClick={handleReset}
                    >
                      Cancel
                    </button>
                    <button
                      id="SaveStyle"
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary font-weight-bold"
                    >
                      Save
                    </button>
                  </div>
                </>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
      <Card className="w-100 mt-5">
        <CardHeader>
          <CardHeaderToolbar>
            <div className="font-weight-bolder h4 pt-2">Change Password</div>
          </CardHeaderToolbar>
        </CardHeader>
        <Divider className="w-100" style={{ color: '#C0C0C0' }} />
        <CardBody>
          <Formik
            enableReinitialize
            initialValues={initialValuesPassword}
            validationSchema={formValidationSchemaPassword}
            key="key_password_form"
            onSubmit={onSubmitPassword}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleReset,
            }) => (
              <>
                <div className="row">
                  <div className="col-sm-4">
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      label="Current Password"
                      name={`${new Date().getTime()}`}
                      value={values.current}
                      onChange={handleChange('current')}
                      margin="normal"
                      variant="outlined"
                      error={errors.current && touched.current}
                      size="small"
                    />
                    <div className="d-flex">
                      {errors.current && touched.current && (
                        <div className="text-danger d-block">
                          {errors.current || ''}
                        </div>
                      )}
                      <span
                        toggle="#password-field"
                        className={showPassword ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10' : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'}
                        style={{ position: 'relative', top: '-35px' }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <TextField
                      type={showNewPassword ? 'text' : 'password'}
                      label="New Password"
                      name={`${new Date().getTime()}`}
                      autoComplete="off"
                      value={values.new}
                      onChange={handleChange('new')}
                      margin="normal"
                      variant="outlined"
                      error={errors.new && touched.new}
                      size="small"
                    />
                    <div className="d-flex">
                      {errors.new && touched.new && (
                        <div className="text-danger d-block">{errors.new || ''}</div>
                      )}
                      <span
                        toggle="#password-field"
                        className={showNewPassword ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10' : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'}
                        style={{ position: 'relative', top: '-35px' }}
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <TextField
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      name={`${new Date().getTime()}`}
                      autoComplete="new-password"
                      value={values.confirm}
                      onChange={handleChange('confirm')}
                      margin="normal"
                      variant="outlined"
                      error={errors.confirm && touched.confirm}
                      size="small"
                    />
                    <div className="d-flex">
                      {errors.confirm && touched.confirm && (
                        <div className="text-danger d-block">
                          {errors.confirm || ''}
                        </div>
                      )}
                      <span
                        toggle="#password-field"
                        className={showConfirmPassword ? 'cursor-pointer fa fa-fw fa-eye ml-auto mr-10' : 'cursor-pointer fa fa-eye-slash ml-auto mr-10'}
                        style={{ position: 'relative', top: '-35px' }}
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-10 alert alert-custom alert-dismissible">
                    <PasswordStrengthIndicator password={values.new} />
                  </div>
                  <div className="align-self-center ml-auto">
                    <button
                      type="button"
                      className="btn btn-secondary font-weight-bold"
                      onClick={() => {
                        clearMesaage()
                        handleReset()
                      }}
                    >
                      Cancel
                  </button>
                    <button
                      id="SaveStyle"
                      type="submit"
                      className="btn btn-primary font-weight-bold"
                      onClick={() => {
                        clearMesaage()
                        handleSubmit()
                      }}
                    >
                      Save
                  </button>
                  </div>
                </div>
                {successMessage && (
                  <div
                    className="row justify-content-center font-weight-bolder"
                    style={{ color: '#008000' }}
                  >
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div
                    className="row justify-content-center font-weight-bolder"
                    style={{ color: '#FF0000' }}
                  >
                    {errorMessage}
                  </div>
                )}
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
      {/* custom loader */}
      <CustomLoadingAnimation isLoading={loading} />

      <Modal
        show={imageCropperModel}
        onHide={() => {
          setImageCropperModel(false)
          setModelImage(previousImage || null)
        }}
        centered
        size="xl"
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ maxHeight: `calc(100vh - 100px)` }}
          >
            <ReactCrop
              src={modelImage}
              onImageLoaded={setCroppingImage}
              crop={crop}
              onChange={setCrop}
              imageStyle={{
                maxWidth: '500px',
                maxHeight: `calc(100vh - 200px)`,
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary font-weight-bold"
            onClick={() => {
              setImageCropperModel(false)
              setModelImage(previousImage || null)
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary font-weight-bold"
            onClick={afterCroppingDone}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
