import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const actionTypes = {
    SET_PERMISSIONS: 'SET_PERMISSIONS',
    RESET_PERMISSIONS: 'RESET_PERMISSIONS'
}

const initialState = {
    permissions: {}
}

export const reducer = persistReducer(
    { storage, key: 'client-permission-data' },
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.SET_PERMISSIONS: {
                return { permissions: { ...action.payload } }
            }
            case actionTypes.RESET_PERMISSIONS: {
                return { permissions: {} }
            }
            default:
                return state
        }
    },
)

export const permissionActions = {
    setPermissions: (payload) => ({ type: actionTypes.SET_PERMISSIONS, ...payload }),
    resetPermissions: () => ({ type: actionTypes.RESET_PERMISSIONS })
}