import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import CustomToast from '../../../components/CustomToast/CustomToast'
import { forgotPassword } from '../_redux/authCrud'
import { connect } from 'react-redux'
import * as auth from '../_redux/authRedux'

function PasswordVerification(props) {
  const initialValues = {
    email: '',
  }

  const { intl } = props

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  })

  const requestPassword = async (values, { setStatus, setSubmitting }) => {
    try {
      await forgotPassword(values.email)
      toast(
        <CustomToast
          type="success"
          message={`Verification email has been sent to ${values.email}`}
        />,
      )
    } catch (error) {
      setStatus(
        intl.formatMessage(
          { id: 'AUTH.VALIDATION.NOT_FOUND' },
          { name: values.email },
        ),
      )
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: requestPassword,
  })

  return (
    <div className="login-form login-forgot" style={{ display: 'block' }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.FORGOT.TITLE" />
        </h3>

        <div className="text-muted font-weight-bold">
          Enter your email to reset the password
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        <div className="form-group fv-plugins-icon-container">
          <TextField
            key="email_input"
            id="outlined-email"
            label="Email"
            autoComplete="off"
            variant="outlined"
            margin="none"
            size="medium"
            fullWidth
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bolder px-7 py-3 my-3 mx-4"
            disabled={formik.isSubmitting}
            style={{ borderRadius: '2px' }}
          >
            Submit
          </button>
          <Link to="/">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-secondary font-weight-bolder px-7 py-3 my-3 mx-4"
              style={{ borderRadius: '2px' }}
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(PasswordVerification))
